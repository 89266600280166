import React from "react";
import { Link, useParams } from "react-router-dom";
import Title from "components/Shared/Title";
import Button from "components/Shared/Button";

export const PostCreateOrderPage = (): React.ReactElement => {
  const { orderId } = useParams();

  return (
    <div
      className="w-full h-full flex flex-col items-center justify-center bg-white rounded-lg p-4 lg:p-10 mobile-full-screen"
      data-testid="noItems">
      <img src="/images/svg/happy.svg" />
      <Title className="pt-10 pb-3 text-center">Order created!</Title>
      <p className="text-grey-50 px-4 text-center">
        Awesome! Now you can send an invoice to your customer.
      </p>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto items-center mt-4">
        <Link to={"/sales/orders"} data-testid="" className="w-full md:w-auto">
          <Button
            type="button"
            className="w-full md:w-60 !bg-black"
            gradient={false}
            data-testid="closedButton">
            Close
          </Button>
        </Link>
        <Link to={`/sales/orders/${orderId}/invoice`} data-testid="" className="w-full md:w-auto">
          <Button type="submit" data-testid="invoiceButton" className="w-full md:w-60">
            Send invoice
          </Button>
        </Link>
      </div>
    </div>
  );
};
