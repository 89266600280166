import { useQuery } from "@tanstack/react-query";
import api from "config/api";
import { FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { ProductsWithVariants, ProductWithVariants, Response } from "types";
import { AlertType } from "types/enum";

export const useProductsWithVariants = () => {
  const { showAlert } = useAlert();

  const handleGetProductsWithVariants = async (): Promise<ProductWithVariants[]> => {
    try {
      const json: Response<ProductsWithVariants> = await api
        .get("product?showVariants=true")
        .json();
      if (json.code === 200) {
        return json.data.products;
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    return [];
  };

  const { data = [], isLoading } = useQuery({
    queryKey: ["products_with_variants"],
    queryFn: handleGetProductsWithVariants
  });

  return {
    loaders: {
      fetchingProduct: isLoading
    },
    productsWithVariants: data
  };
};

export type useProductsWithVariantsType = ReturnType<typeof useProductsWithVariants>;
