import Autocomplete from "components/Shared/Autocomplete";
import Tag from "components/Shared/Tag/Tag";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";
import React from "react";
import { AttachComponentsProps, FormChangeEvent } from "types";

export const AttachComponents = ({
  handleAttachComponent,
  handleDetachComponent,
  storeItems,
  name,
  components,
  setCreateIsOpen
}: AttachComponentsProps): React.ReactElement => {
  return (
    <>
      <div>
        <Autocomplete
          type="text"
          name="storeItems"
          onChange={(e: FormChangeEvent) => {
            if (e.target.name == "itemId" && e.target.value) {
              handleAttachComponent(e.target.value);
            }
          }}
          value=""
          autoComplete="off"
          label="Attach store item"
          data-testid="storeItems"
          dataIdName="itemId"
          data={
            storeItems?.items
              .filter((item) => !components.find((component) => component.storeItemId === item.id))
              .map((item) => ({ id: item.id, name: getStoreItemName(item) })) || []
          }
          placeholder="Start typing to filter store items"
          defaultData={
            <span className="text-grey-50 text-sm">
              Can’t see what you are looking for?&nbsp;
              <span
                onClick={() => setCreateIsOpen(true)}
                className="link cursor-pointer inline-flex">
                <b>Create a new store item here</b>
              </span>
            </span>
          }
        />
        <div>
          <p className="font-bold">Active store items</p>
          <div className="flex mt-4 flex-wrap" data-testid="attachedComponents">
            {components.map((component) => (
              <Tag
                key={component.id}
                name={component.name}
                onRemove={() => handleDetachComponent(component.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-6" data-testid="">
        {components.length > 0
          ? `Set the store item measurements for ${name} variants.`
          : "No store items attached."}
      </p>
    </>
  );
};
