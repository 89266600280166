import React from "react";
import { TemplateDetail } from "components/TemplateDetail/TemplateDetail";
import LoadingComponent from "components/Shared/Loading";
import { useTemplate } from "hooks/useTemplate/useTemplate";

const TemplateDetailPage = (): React.ReactElement => {
  const props = useTemplate();

  return (
    <LoadingComponent loading={props.loaders.fetchingTemplate && !props.template}>
      {props.template && <TemplateDetail library={false} {...props} template={props.template} />}
    </LoadingComponent>
  );
};

export default TemplateDetailPage;
