import ProductConfiguration from "components/ProductConfiguration";
import LoadingComponent from "components/Shared/Loading";
import useProduct from "hooks/useProduct";
import { useProductsWithVariants } from "hooks/useProduct/useProductsWithVariants";
import useProductActivity from "hooks/useProductActivities/useProductActivities";
import { useProductAddOn } from "hooks/useProductAddOn/useProductAddOn";
import useProductComponent from "hooks/useProductComponent";
import useStoreItemProducts from "hooks/useStoreItemProducts";
import useStoreItems from "hooks/useStoreItems";
import ArrowLeft from "icons/ArrowLeft";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CreateStoreItem from "components/CreateStoreItem";
import CreateStoreItemProduct from "components/CreateStoreItemProduct";
import { CreateDialog } from "components/Shared/CreateDialog/CreateDialog";
import Dialog from "components/Shared/Dialog";

const ConfigureProductPage = (): React.ReactElement => {
  const { loaders, productDetail, productId } = useProduct();
  const productComponents = useProductComponent();
  const productAddOns = useProductAddOn();
  const productActivities = useProductActivity();

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [fromProductIsOpen, setFromProductsIsOpen] = useState(false);
  const [rawIsOpen, setRawIsOpen] = useState(false);

  const storeItemProps = useStoreItems(true, () => {
    setRawIsOpen(false);
    setCreateIsOpen(false);
  });
  const { storeItems, measurementUnits } = storeItemProps;
  const storeItemProductsProps = useStoreItemProducts(true, () => {
    setFromProductsIsOpen(false);
    setCreateIsOpen(false);
  });
  const { productsWithVariants } = useProductsWithVariants();

  return (
    <LoadingComponent loading={loaders.fetchingProduct && !productDetail}>
      {productDetail && (
        <>
          <Link to={`/products/${productDetail.id}`} className="font-bold text-sm	flex px-4 md:px-0">
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back to variants
          </Link>
          <ProductConfiguration
            productDetail={productDetail}
            productComponents={productComponents}
            storeItems={storeItems}
            measurementUnits={measurementUnits}
            productAddOns={productAddOns}
            productActivities={productActivities}
            setCreateIsOpen={setCreateIsOpen}
          />
        </>
      )}
      <CreateDialog
        isOpen={createIsOpen}
        setIsOpen={setCreateIsOpen}
        title="How do you want to add your store item?"
        option1={{
          title: "From a Product",
          desc: "Add from your products to the store",
          onClick: () => setFromProductsIsOpen(true)
        }}
        option2={{
          title: "From Raw Material",
          desc: "Add a new raw material to the store",
          onClick: () => setRawIsOpen(true)
        }}
      />
      <Dialog
        isOpen={fromProductIsOpen}
        setIsOpen={setFromProductsIsOpen}
        titleClassName="text-center"
        title="Add from a product">
        <CreateStoreItemProduct
          {...storeItemProductsProps}
          measurementUnits={storeItemProps.measurementUnits}
          products={productsWithVariants}
          excludedProductId={productId}
        />
      </Dialog>
      <Dialog
        isOpen={rawIsOpen}
        setIsOpen={setRawIsOpen}
        titleClassName="text-center"
        title="Add from a raw material">
        <CreateStoreItem {...storeItemProps} />
      </Dialog>
    </LoadingComponent>
  );
};

export default ConfigureProductPage;
