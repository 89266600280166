import { useState } from "react";
import { UseSearchType } from "types";

export const useSearch = <T, K extends keyof T>(defaultSearch = ""): UseSearchType<T, K> => {
  const [search, setSearch] = useState(defaultSearch);

  const filter = <T, K extends keyof T>(key: K, data?: T[]): T[] => {
    return (data || [])?.filter((item) =>
      String(item[key]).trim().toLowerCase().includes(search.trim().toLowerCase())
    );
  };

  const advancedFilter = <T>(getFilterProp: (item: T) => string, data?: T[]): T[] => {
    return (data || [])?.filter((item) =>
      getFilterProp(item).trim().toLowerCase().includes(search.trim().toLowerCase())
    );
  };

  return {
    search,
    setSearch,
    filter,
    advancedFilter
  };
};
