import React, { ReactNode, RefObject, SVGProps } from "react";
import cx from "classnames";
import { useComponentVisible } from "hooks/shared/useComponentVisible/useComponentVisible";
import { useNavigate } from "react-router-dom";

export const DropdownButton = ({
  items,
  children,
  defaultClick
}: {
  items: { text: ReactNode; onClick?: () => void; link?: string }[];
  children: (caretProps: SVGProps<SVGSVGElement> & { open: boolean }) => React.ReactElement;
  defaultClick?: () => void;
}): React.ReactElement => {
  const { ref, isComponentVisible } = useComponentVisible(false);
  const navigate = useNavigate();

  return (
    <span
      data-dropdown-toggle="dropdown"
      data-dropdown-placement="bottom"
      className="font-medium text-sm tems-center justify-between text-dark-grey relative inline-flex">
      <div
        className={cx(
          "rounded-md bg-white shadow-md cursor-pointer absolute left-0 min-w-full top-full mt-2 hidden overflow-hidden z-10",
          isComponentVisible && "!block"
        )}>
        <div className="flex flex-col font-bold">
          {items.map((item) => (
            <a
              href="#"
              key={item.text?.toString()}
              className="px-4 py-2 hover:bg-primary hover:text-white"
              onClick={() => {
                item.onClick?.();
                item.link && navigate(item.link);
              }}>
              {item.text}
            </a>
          ))}
        </div>
      </div>
      <span
        ref={defaultClick ? undefined : (ref as RefObject<HTMLSpanElement>)}
        onClick={defaultClick}>
        {children({
          open: isComponentVisible,
          className: "ml-4",
          ref: defaultClick ? (ref as RefObject<SVGSVGElement>) : undefined
          // onClick: (e) => e.stopPropagation()
        })}
      </span>
    </span>
  );
};
