import Amount from "components/Shared/Amount";
import Disclosure from "components/Shared/Disclosure";
import Table from "components/Shared/Table";
import React from "react";
import { OrderDetail, ProductOrderItem } from "types";

export const OrderItems = ({
  items,
  discount,
  tax,
  amountTotal,
  amountDue,
  amountPaid
}: Pick<OrderDetail, "items" | "discount" | "tax" | "amountTotal"> & {
  amountDue?: number;
  amountPaid?: number;
}): React.ReactElement => {
  return (
    <>
      <div className="overflow-x-auto hidden sm:block">
        <Table className="table-fixed">
          <Table.Head>
            <Table.Row>
              <Table.HeadData colSpan={2}>Product</Table.HeadData>
              <Table.HeadData>Variant</Table.HeadData>
              <Table.HeadData>Quantity</Table.HeadData>
              <Table.HeadData className="text-right">Unit Price</Table.HeadData>
              <Table.HeadData>Amount</Table.HeadData>
            </Table.Row>
          </Table.Head>
          <Table.Body className="text-dark-grey" data-testid="items">
            {items.map((item): React.ReactElement => {
              return (
                <Table.Row key={item.value.id} className="" data-testid="item">
                  <td colSpan={6} className="bg-soft-grey rounded-lg">
                    <Table className="border-spacing-y-0 table-fixed">
                      <Table.Head>
                        <Table.Row>
                          <th
                            colSpan={3}
                            className="px-4 pb-2 pt-0 md:px-6 border-b border-b-grey-30 text-dark-grey !font-normal">
                            {item.value.name}
                          </th>
                          <th
                            colSpan={3}
                            className="px-4 pb-2 pt-0 md:px-6 border-b border-b-grey-30 text-dark-grey !font-normal">
                            {item.value.quantity}
                          </th>
                        </Table.Row>
                      </Table.Head>
                      <Table.Body className="text-dark-grey" data-testid="items">
                        {item.value.items.map((groupedItem) => (
                          <OrderItem
                            key={groupedItem.id}
                            item={groupedItem}
                            groupQuantity={item.value.quantity}
                          />
                        ))}
                      </Table.Body>
                    </Table>
                  </td>
                </Table.Row>
              );
            })}
            {/* Discount */}
            {discount && (
              <Table.Row>
                <Table.Data className="!bg-white" />
                <Table.Data
                  data-testid="total"
                  className="!bg-white text-right !py-0 font-bold"
                  colSpan={4}>
                  Discount
                  {discount.type == "PERCENTAGE" && `(${discount.value}%)`}:
                </Table.Data>
                <Table.Data className="!bg-white !py-0" data-testid="amountTotal">
                  <Amount amount={discount.discountedAmount} />
                </Table.Data>
              </Table.Row>
            )}
            {/* Tax */}
            {tax && (
              <Table.Row>
                <Table.Data className="!bg-white" />
                <Table.Data
                  data-testid="total"
                  className="!bg-white text-right !py-0 font-bold"
                  colSpan={4}>
                  Tax ({tax.taxRate}%):
                </Table.Data>
                <Table.Data className="!bg-white !py-0" data-testid="amountTotal">
                  <Amount amount={tax.taxAmount} />
                </Table.Data>
              </Table.Row>
            )}
            {/* hr */}
            {(tax || discount) && (
              <Table.Row>
                <Table.Data colSpan={6} className="!p-0">
                  <hr className="flex-1 h-0.5 m-0 border-soft-grey" />
                </Table.Data>
              </Table.Row>
            )}
            {/* Total */}
            <Table.Row>
              <Table.Data className="!bg-white" />
              <Table.Data
                data-testid="total"
                className="!bg-white text-right !py-0 font-bold"
                colSpan={4}>
                Total:
              </Table.Data>
              <Table.Data className="!bg-white !py-0" data-testid="amountTotal">
                <Amount amount={amountTotal} />
              </Table.Data>
            </Table.Row>

            {/* Amount paid */}
            {amountPaid !== undefined && (
              <Table.Row>
                <Table.Data className="!bg-white" />
                <Table.Data
                  data-testid="amountPaid"
                  className="!bg-white text-right !py-0 font-bold"
                  colSpan={4}>
                  Amount paid:
                </Table.Data>
                <Table.Data className="!bg-white !py-0" data-testid="amountPaid">
                  <Amount amount={amountPaid} />
                </Table.Data>
              </Table.Row>
            )}
            {/* Amount due */}
            {amountDue !== undefined && (
              <>
                {/* hr */}
                <Table.Row>
                  <Table.Data colSpan={6} className="!p-0">
                    <hr className="flex-1 h-0.5 m-0 border-soft-grey" />
                  </Table.Data>
                </Table.Row>
                <Table.Row>
                  <Table.Data className="!bg-white" />
                  <Table.Data
                    data-testid="total"
                    className="!bg-white text-right font-bold !py-0"
                    colSpan={4}>
                    Amount due:
                  </Table.Data>
                  <Table.Data className="!bg-white font-bold !py-0" data-testid="amountDue">
                    <Amount amount={amountDue} />
                  </Table.Data>
                </Table.Row>
              </>
            )}
          </Table.Body>
        </Table>
      </div>
      <div className="block sm:hidden mt-4">
        <div className="flex justify-between">
          <div className="flex-grow">
            <span className="font-bold text-grey-50 px-4 inline-flex w-1/2">Variant</span>
            <span className="font-bold text-grey-50 inline-flex w-1/2">Amount</span>
          </div>
          <span className="w-6"></span>
        </div>
        {items.map(
          (item): React.ReactElement => (
            <Disclosure key={item.value.id}>
              <div className="flex">
                <span className="font-bold w-1/2" data-testid="variantName">
                  {item.value.name}
                </span>
                <span className="font-bold w-1/2" data-testid="totalPrice"></span>
              </div>

              {item.type == "group" &&
                item.value.items.map((groupedItem, index) => (
                  <React.Fragment key={groupedItem.id}>
                    {index > 0 && <hr className="flex-1 h-0.5 m-0 mt-2 border-grey-30" />}
                    <MobileOrderItem key={groupedItem.id} item={groupedItem} />
                  </React.Fragment>
                ))}
            </Disclosure>
          )
        )}
        <div className="space-y-3 mt-4 px-4">
          {discount && (
            <p className="flex">
              <span className="flex-grow">
                <b className="inline-flex w-1/2">
                  Discount
                  {discount.type == "PERCENTAGE" && `(${discount.value}%)`}:
                </b>
                <Amount amount={discount.discountedAmount} />
              </span>
              <span className="w-6"></span>
            </p>
          )}
          {tax && (
            <p className="flex">
              <span className="flex-grow">
                <b className="inline-flex w-1/2">Tax ({tax.taxRate}%):</b>
                <Amount amount={tax.taxAmount} />
              </span>
              <span className="w-6"></span>
            </p>
          )}
          {(tax || discount) && <hr className="flex-1 h-0.5 m-0 border-soft-grey" />}
          <p className="flex">
            <span className="flex-grow">
              <b className="inline-flex w-1/2">Total:</b>
              <Amount amount={amountTotal} />
            </span>
            <span className="w-6"></span>
          </p>
          {amountPaid !== undefined && (
            <p className="flex">
              <span className="flex-grow">
                <b className="inline-flex w-1/2">Amount paid:</b>
                <Amount amount={amountPaid} />
              </span>
              <span className="w-6"></span>
            </p>
          )}
          {amountDue !== undefined && (
            <>
              <hr className="flex-1 h-0.5 m-0 border-soft-grey" />
              <p className="flex">
                <span className="flex-grow">
                  <b className="inline-flex w-1/2">Amount due:</b>
                  <Amount amount={amountDue} />
                </span>
                <span className="w-6"></span>
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const OrderItem = ({
  item,
  groupQuantity
}: {
  item: ProductOrderItem;
  groupQuantity?: number;
}): React.ReactElement => {
  return (
    <Table.Row className="rounded-lg" data-testid="item">
      <Table.Data data-testid="productName" className="font-bold" colSpan={2}>
        {item.product.name}
      </Table.Data>
      <Table.Data data-testid="variantName">{item.variant.name}</Table.Data>
      <Table.Data className="font-bold" data-testid="quantity">
        {item.quantity}
        {groupQuantity && <small className="font-normal">x{groupQuantity}</small>}
      </Table.Data>
      <Table.Data className="text-right" data-testid="unitPrice">
        <Amount amount={item.unitPrice} />
      </Table.Data>
      <Table.Data data-testid="totalPrice" className="">
        <Amount amount={item.totalPrice} />
      </Table.Data>
    </Table.Row>
  );
};

const MobileOrderItem = ({ item }: { item: ProductOrderItem }): React.ReactElement => {
  return (
    <div className="flex flex-col">
      <small className="mt-2" data-testid="product">
        <span className="font-bold w-20 inline-flex">Product:</span> {item.product.name}
      </small>
      <small className="mt-2" data-testid="">
        <span className="font-bold w-20 inline-flex">Quantity:</span>
        <Amount amount={item.quantity} />
      </small>
      <small className="mt-2">
        <span className="font-bold w-20 inline-flex">Unit price:</span>
        <Amount amount={item.unitPrice} />
      </small>
    </div>
  );
};
