import React from "react";

const CaretRight = (): React.ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_245_1606" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_245_1606)">
        <path
          d="M8.0251 21.65L6.6001 20.225L14.8251 12L6.6001 3.77498L8.0251 2.34998L17.6751 12L8.0251 21.65Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default CaretRight;
