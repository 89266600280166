import Title from "components/Shared/Title";
import { UseProductOfferingsType } from "hooks/useProductOfferings/useProductOfferings";
import React, { useState } from "react";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import { Link, useSearchParams } from "react-router-dom";
import { NavOrder } from "icons/NavOrder";
import Button from "components/Shared/Button";
import { TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { TabTitle } from "components/Shared/Tab/Tab";
import Extension from "icons/Extension";
import Customize from "icons/Customize";
import { ProductOfferings } from "./ProductOfferings/ProductOfferings";
import { ShopfrontProps } from "types";
import { Collections } from "./Collections/Collections";

export const Shopfront = (props: ShopfrontProps): React.ReactElement => {
  const { productOfferings } = props;
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const tabs = ["offerings", "collections"];

  const [selectedIndex, setSelectedIndex] = useState(tab ? tabs.indexOf(tab) : 0);

  return (
    <div className="px-4 md:px-0">
      <div className="flex flex-col sm:flex-row justify-between sm:items-center space-y-4 sm:space-y-0 mb-6">
        <Title className="py-2" data-testid="title">
          Shopfront
          <InfoTooltip>
            Package your products for customers as offerings then group offerings together to form
            collections.
          </InfoTooltip>
        </Title>
        {/* <Link to="/products/create" data-testid="createProduct">
          <Button icon={<NavOrder />}>Create & share cart</Button>
        </Link> */}
      </div>
      <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <TabList className="bg-white flex text-grey-50 font-bold">
          <TabTitle
            title="Product Offerings"
            isSelected={selectedIndex == 0}
            icon={<Extension />}
          />
          <TabTitle title="Collections" isSelected={selectedIndex == 1} icon={<Customize />} />
        </TabList>

        <TabPanels>
          <TabPanel>
            <ProductOfferings productOfferings={productOfferings} />
          </TabPanel>
          <TabPanel>
            <Collections />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};
