import React from "react";
import { TemplateVariantsProps } from "types";
import { Link } from "react-router-dom";
import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import ArrowLeft from "icons/ArrowLeft";
import Title from "components/Shared/Title";
import Edit from "icons/Edit";
import Table from "components/Shared/Table";
import Disclosure from "components/Shared/Disclosure";
import Dropdown from "components/Shared/Dropdown";
import Kebeb from "icons/Kebeb";
import Settings from "icons/Settings";
import Sort from "components/Shared/Sort";
import Delete from "icons/Delete";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";

export const TemplateVariants = ({
  template,
  sort,
  deleteVariant
}: TemplateVariantsProps): React.ReactElement => {
  return (
    <div className="">
      <Link
        to={`/templates/published/${template.id}`}
        className="font-bold text-sm	inline-flex px-4 md:px-0">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to template
      </Link>
      <div className="mt-3 flex flex-col sm:flex-row justify-between sm:items-center space-y-4 sm:space-y-0 px-4 md:px-0 mb-6">
        <Title data-testid="productName">{template.name}</Title>
        <Link to={`/templates/published/${template.id}/configure`} data-testid="configure">
          <IconButton icon={<Settings />}>Configure template</IconButton>
        </Link>
      </div>
      <div className="">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <p className="text-grey-50 px-4 md:px-0 mt-2 sm:mt-0" data-testid="variantDesc">
            These are different attributes of your master product.
          </p>
          <div className="overflow-y-unset hidden sm:block">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.HeadData {...sort} field="name" className="w-full">
                    Variants
                  </Table.HeadData>
                  <Table.HeadData />
                </Table.Row>
              </Table.Head>
              <Table.Body data-testid="variants">
                {template.variants.map((variant) => (
                  <Table.Row
                    key={variant.id}
                    className="rounded-lg"
                    data-testid="variant"
                    to={`${variant.id}/edit`}>
                    <Table.Data className="font-bold" data-testid="variantName">
                      {variant.name}
                    </Table.Data>
                    <Table.Data className="text-dark-grey">
                      <Dropdown
                        menu={[
                          {
                            text: "Edit",
                            icon: <Edit />,
                            link: `${variant.id}/edit`
                          },
                          {
                            text: "Delete",
                            icon: <Delete />,
                            onClick: () => deleteVariant.handleConfirmDelete(variant)
                          }
                        ]}>
                        <Kebeb />
                      </Dropdown>
                    </Table.Data>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <div className="block sm:hidden mt-4">
            <Sort {...sort} fields={[{ field: "name", name: "Variants" }]} />
            <div className="flex">
              <span className="font-bold text-grey-50 w-4/5 px-4">Variants</span>
              <span className="w-1/5"></span>
            </div>
            {template.variants.map(
              (variant): React.ReactElement => (
                <Disclosure key={variant.id}>
                  <Link className="flex" to={`${variant.id}/edit`}>
                    <span className="font-bold w-full" data-testid="variantName">
                      {variant.name}
                    </span>
                  </Link>
                  <span className="flex flex-col mt-2">
                    <Link to={`${variant.id}/edit`} className="mobile-link">
                      <Edit className="mobile-icon" />
                      <span>Edit</span>
                    </Link>
                  </span>
                </Disclosure>
              )
            )}
          </div>
          {template.variants.length == 0 && (
            <div data-testid="noActivities" className="p-4 md:px-6">
              No variants created for this template
            </div>
          )}
          <Link to="create" data-testid="createVariant">
            <IconButton icon={<Plus />} data-testid="createVariant" outlined>
              Add new variant
            </IconButton>
          </Link>
        </div>
      </div>
      <ConfirmationDialog
        message={deleteVariant.deleteConfirmationMessage}
        handleContinue={deleteVariant.handleDeleteConfirmation}
        handleCancel={deleteVariant.handleDeleteCancellation}
      />
    </div>
  );
};
