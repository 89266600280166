import React, { SVGProps } from "react";

export const Hamburger = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="mask0_3453_6936" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3453_6936)">
        <path
          d="M3.25 17.6344V16.1345H20.75V17.6344H3.25ZM3.25 12.7498V11.2499H20.75V12.7498H3.25ZM3.25 7.86521V6.36523H20.75V7.86521H3.25Z"
          fill="#00A89D"
        />
      </g>
    </svg>
  );
};
