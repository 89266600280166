import React from "react";

export const NavTemplateLibrary = (): React.ReactElement => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_3234_5152" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="22">
      <rect y="0.478516" width="21" height="21" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3234_5152)">
      <path
        d="M2.625 17.1035C2.14375 17.1035 1.73177 16.9322 1.38906 16.5895C1.04635 16.2467 0.875 15.8348 0.875 15.3535V6.60352C0.875 6.12227 1.04635 5.71029 1.38906 5.36758C1.73177 5.02487 2.14375 4.85352 2.625 4.85352H11.375C11.8562 4.85352 12.2682 5.02487 12.6109 5.36758C12.9536 5.71029 13.125 6.12227 13.125 6.60352V15.3535C13.125 15.8348 12.9536 16.2467 12.6109 16.5895C12.2682 16.9322 11.8562 17.1035 11.375 17.1035H2.625ZM15.75 10.1035C15.5021 10.1035 15.2943 10.0197 15.1266 9.85195C14.9589 9.68425 14.875 9.47643 14.875 9.22852V5.72852C14.875 5.4806 14.9589 5.27279 15.1266 5.10508C15.2943 4.93737 15.5021 4.85352 15.75 4.85352H19.25C19.4979 4.85352 19.7057 4.93737 19.8734 5.10508C20.0411 5.27279 20.125 5.4806 20.125 5.72852V9.22852C20.125 9.47643 20.0411 9.68425 19.8734 9.85195C19.7057 10.0197 19.4979 10.1035 19.25 10.1035H15.75ZM16.625 8.35352H18.375V6.60352H16.625V8.35352ZM2.625 15.3535H11.375V6.60352H2.625V15.3535ZM3.5 13.6035H10.5L8.20312 10.541L6.5625 12.7285L5.35938 11.1316L3.5 13.6035ZM15.75 17.1035C15.5021 17.1035 15.2943 17.0197 15.1266 16.852C14.9589 16.6842 14.875 16.4764 14.875 16.2285V12.7285C14.875 12.4806 14.9589 12.2728 15.1266 12.1051C15.2943 11.9374 15.5021 11.8535 15.75 11.8535H19.25C19.4979 11.8535 19.7057 11.9374 19.8734 12.1051C20.0411 12.2728 20.125 12.4806 20.125 12.7285V16.2285C20.125 16.4764 20.0411 16.6842 19.8734 16.852C19.7057 17.0197 19.4979 17.1035 19.25 17.1035H15.75ZM16.625 15.3535H18.375V13.6035H16.625V15.3535Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
