import { PublishedTemplates } from "components/PublishedTemplates/PublishedTemplates";
import LoadingComponent from "components/Shared/Loading";
import { useTemplates } from "hooks/useTemplates/useTemplates";
import React from "react";

const PublishedTemplatesPage = (): React.ReactElement => {
  const templateLibraryProps = useTemplates();

  return (
    <LoadingComponent loading={templateLibraryProps.loaders.fetchingTemplates}>
      <PublishedTemplates {...templateLibraryProps} />
    </LoadingComponent>
  );
};

export default PublishedTemplatesPage;
