import { auth, provider } from "config/firebase";
import { useAppState } from "config/store";
import {
  getAdditionalUserInfo,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithRedirect
} from "firebase/auth";
import { identify, track } from "helpers/analytics";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SegmentEvent } from "types/enum";
import { UseSSOAuthType } from "types/sso";

const useSSOAuth = (): UseSSOAuthType => {
  const navigate = useNavigate();
  const appState = useAppState();

  const handleGoogleAuth = async (): Promise<void> => {
    navigate("/sso-auth");
    signInWithRedirect(auth, provider);
  };

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          // The signed-in user info.
          const user = result.user;
          appState.user.set(user);
          identify();
          const isFirstLogin = getAdditionalUserInfo(result)?.isNewUser;
          if (isFirstLogin === true) {
            track(SegmentEvent.SIGNED_UP, {
              provider: "google"
            });
          } else {
            track(SegmentEvent.SIGNED_IN, {
              provider: "google"
            });
          }
          navigate("/post-auth");
        }
      })
      .catch((error) => {
        // // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // // ...
      });
  }, []);

  return {
    handleGoogleAuth
  };
};

export default useSSOAuth;
