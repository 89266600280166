import { removeAtIndex, replaceAtIndex } from "helpers/array";
import { undefinedToString } from "helpers/string";
import { resetProduct } from "hooks/useOrderForm/useOrderForm.utils";
import { Dispatch, SetStateAction, useRef } from "react";
import {
  FormChangeEvent,
  GroupedProduct,
  OrderProductsForm,
  ProductDetailVariant,
  ProductOfferingOption,
  ProductWithVariants
} from "types";
import { v4 } from "uuid";

export const useOrderProductsForm = <T extends OrderProductsForm>(
  setForm: Dispatch<SetStateAction<T>>,
  setError: Dispatch<SetStateAction<string>>
) => {
  const orderFormProducts = useRef<OrderProductsForm["products"]>([
    {
      key: v4(),
      type: "group",
      value: {
        name: "",
        quantity: 1,
        items: [
          {
            groupId: "",
            productId: "",
            variantId: "",
            quantity: "1",
            unitPrice: ""
          }
        ]
      }
    }
  ]);

  const handleGroupedNameChange = (event: FormChangeEvent, productIndex: number): void => {
    const existingProduct = orderFormProducts.current[productIndex];

    const { name, value } = event.target;
    setForm((prev) => {
      const products = replaceAtIndex(
        prev.products,
        {
          ...existingProduct,
          value: { ...existingProduct.value, [name]: value }
        },
        productIndex
      );
      orderFormProducts.current = products;
      return {
        ...prev,
        products
      };
    });
    setError("");
  };

  const handleGroupedProductChange = (
    event: FormChangeEvent,
    productIndex: number,
    itemIndex: number,
    product: ProductWithVariants | undefined,
    variant: ProductDetailVariant | undefined
  ): void => {
    const existingProduct = orderFormProducts.current[productIndex];

    const { name, value } = event.target;
    setForm((prev) => {
      const groupNameIsEmpty = !existingProduct.value.name;
      const products: GroupedProduct[] = replaceAtIndex(
        prev.products,
        {
          ...existingProduct,
          value: {
            ...existingProduct.value,
            name:
              groupNameIsEmpty && name == "productId"
                ? undefinedToString(product?.name)
                : existingProduct.value.name,
            items: replaceAtIndex(
              existingProduct.value.items,
              {
                ...existingProduct.value.items[itemIndex],
                ...resetProduct(event, variant),
                [name]: value
              },
              itemIndex
            )
          }
        },
        productIndex
      );
      orderFormProducts.current = products;
      return {
        ...prev,
        products
      };
    });
    setError("");
  };

  const handleAddGroupedProduct = (): void => {
    setForm((prev) => {
      const products: OrderProductsForm["products"] = [
        ...prev.products,
        {
          key: v4(),
          type: "group",
          value: {
            name: "",
            quantity: 1,
            items: [{ productId: "", groupId: "", variantId: "", quantity: "1", unitPrice: "" }]
          }
        }
      ];
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
  };

  const handleAddProductInGroup = (productIndex: number): void => {
    const existingProduct = orderFormProducts.current[productIndex];

    setForm((prev) => {
      const products = replaceAtIndex(
        prev.products,
        {
          ...existingProduct,
          value: {
            ...existingProduct.value,
            items: [
              ...existingProduct.value.items,
              { productId: "", groupId: "", variantId: "", quantity: "1", unitPrice: "" }
            ]
          }
        },
        productIndex
      );
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
  };

  const handleDuplicateGroupedProduct = (productIndex: number): void => {
    const existingProduct = orderFormProducts.current[productIndex];

    setForm((prev) => {
      const products = [
        ...prev.products,
        {
          ...existingProduct,
          key: v4(),
          value: {
            ...existingProduct.value,
            name: ""
          }
        }
      ];
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
  };

  const handleRemoveProduct = (productIndex: number): void => {
    setForm((prev) => {
      const products = removeAtIndex(prev.products, productIndex);
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
    setError("");
  };

  const handleRemoveProductInGroup = (productIndex: number, itemIndex: number): void => {
    const groupedProducts = orderFormProducts.current[productIndex];

    const newGroupedProductsItems = removeAtIndex(groupedProducts.value.items, itemIndex);
    if (newGroupedProductsItems.length === 0) {
      // All products in the group have been removed, so remove group
      setForm((prev) => {
        const products = removeAtIndex(prev.products, productIndex);
        orderFormProducts.current = products;

        return {
          ...prev,
          products
        };
      });
    } else {
      setForm((prev) => {
        const products = replaceAtIndex(
          prev.products,
          {
            ...groupedProducts,
            value: {
              ...groupedProducts.value,
              items: removeAtIndex(groupedProducts.value.items, itemIndex)
            }
          },
          productIndex
        );
        orderFormProducts.current = products;

        return {
          ...prev,
          products
        };
      });
    }
    setError("");
  };

  const handleSetProducts = (products: OrderProductsForm["products"]): void => {
    setForm((prev) => {
      orderFormProducts.current = products;
      return {
        ...prev,
        products
      };
    });
  };

  const handleAddOrderItemFromOffering = (option: ProductOfferingOption) => {
    setForm((prev) => {
      const products: OrderProductsForm["products"] = [
        ...(prev.products.length == 1 &&
        !prev.products[0].value.name &&
        prev.products[0].value.items.length == 1 &&
        !prev.products[0].value.items[0].productId
          ? []
          : prev.products),
        {
          key: v4(),
          type: "group",
          value: {
            name: option.name,
            quantity: 1,
            items: option.items.map((item) => ({
              productId: item.product.id,
              variantId: item.variant.id,
              quantity: String(item.quantity),
              unitPrice: String(item.unitPrice),
              groupId: ""
            }))
          }
        }
      ];
      orderFormProducts.current = products;

      return {
        ...prev,
        products
      };
    });
  };

  return {
    handleGroupedNameChange,
    handleGroupedProductChange,
    handleAddGroupedProduct,
    handleAddProductInGroup,
    handleDuplicateGroupedProduct,
    handleRemoveProduct,
    handleRemoveProductInGroup,
    handleSetProducts,
    handleAddOrderItemFromOffering
  };
};

export type UseOrderProductsFormType = ReturnType<typeof useOrderProductsForm>;
