import TextInput from "../TextInput";
import { FormChangeEvent, UseSearchType } from "types";
import React from "react";
import Search from "icons/Search";
import Cancel from "icons/Cancel";
import cx from "classnames";

export const SearchInput = <T, K extends keyof T>({
  search,
  setSearch,
  inputSize = "sm"
}: UseSearchType<T, K>): React.ReactElement => {
  const handleFormChange = (event: FormChangeEvent): void => {
    const { value } = event.target;
    setSearch(value);
  };

  return (
    <div className="max-w-md">
      <TextInput
        type="text"
        simple
        name="search"
        onChange={handleFormChange}
        value={search}
        required
        label=""
        placeholder="Start typing to search..."
        data-testid="searchInput"
        inputSize={inputSize}
        className="!pt-0"
        showPlaceHolder
        prepend={
          <span
            className={cx(
              "relative -left-1 -top-[0.6rem] text-grey-50",
              inputSize != "sm" && "-top-[0.5rem]"
            )}>
            <Search width={20} height={20} />
          </span>
        }
        append={
          search ? (
            <span
              className={cx(
                "relative -top-[0.6rem] text-grey-50 cursor-pointer",
                inputSize != "sm" && "-top-[0.5rem]"
              )}
              onClick={() => setSearch("")}
              title="Clear search input">
              <Cancel width={20} height={20} />
            </span>
          ) : undefined
        }
      />
    </div>
  );
};
