import Dropdown from "components/Shared/Dropdown";
import IconButton from "components/Shared/IconButton/IconButton";
import { SearchInput } from "components/Shared/SearchInput/SearchInput";
import { useSearch } from "hooks/shared/useSearch/useSearch";
import Edit from "icons/Edit";
import Kebeb from "icons/Kebeb";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";
import { ProductOffering, ProductOfferingsProps } from "types";
import { NoOfferings } from "./NoOfferings";

export const ProductOfferings = ({
  productOfferings
}: ProductOfferingsProps): React.ReactElement => {
  const search = useSearch<ProductOffering, "name">();
  const filteredItems = search.filter("name", productOfferings);

  return (
    <div className="bg-white sm:rounded-lg p-4 lg:p-6 mt-2 md:mt-7">
      {productOfferings.length > 0 && (
        <div className="flex flex-col sm:flex-row justify-between sm:items-center space-y-4 sm:space-y-0">
          <div className="w-60">
            <SearchInput {...search} />
          </div>
          <Link to="product-offerings/create" data-testid="createProductOffering">
            <IconButton icon={<Plus />}>Add new offering</IconButton>
          </Link>
        </div>
      )}
      <div className="mt-7 pb-10" data-testid="products">
        <div className="space-y-3">
          {filteredItems.map((offering) => (
            <div
              className="flex justify-between bg-soft-grey rounded-lg px-6 py-4"
              key={offering.id}
              data-testid="offering">
              <Link to={`product-offerings/${offering.id}`} className="flex-1">
                <p className="font-bold text-lg" data-testid="productsName">
                  {offering.name}
                </p>
              </Link>
              <div className="flex">
                <Dropdown
                  menu={[
                    {
                      text: "Edit",
                      icon: <Edit />,
                      link: `product-offerings/${offering.id}`
                    }
                  ]}>
                  <Kebeb />
                </Dropdown>
              </div>
            </div>
          ))}
          {filteredItems.length === 0 && (
            <div className="" data-testid="noOfferings">
              <NoOfferings message="Product offerings help you package your products for customers" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
