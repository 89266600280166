/* eslint-disable @typescript-eslint/no-empty-function */
import Amount from "components/Shared/Amount";
import IconButton from "components/Shared/IconButton";
import { DropdownButton } from "components/Shared/DropdownButton/DropdownButton";
import Caret from "icons/Caret";
import React, { useState } from "react";
import { TemplateDetailProps } from "types";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import { Tab } from "@headlessui/react";
import { useSearchParams } from "react-router-dom";
import Extension from "icons/Extension";
import Customize from "icons/Customize";
import { TabTitle } from "components/Shared/Tab/Tab";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize, { defaultSchema } from "rehype-sanitize";
import Markdown from "react-markdown";
import cx from "classnames";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import "components/Shared/MarkdownEditor/Markdown.css";

const ConfigurationSection = (props: {
  title: string;
  options: { name: string }[];
}): React.ReactElement => {
  if (props.options.length == 0) {
    return <></>;
  }
  return (
    <div className="my-8 text-center px-8">
      <span
        data-testid="stepText"
        className={cx(
          "z-10 text-xl",
          "relative after:content-[''] after:w-full after:h-0 after:border-b after:border-8 after:absolute after:left-0 after:right-0 after:bottom-0 after:border-secondary after:-z-10 px-4"
        )}>
        {props.title}
      </span>
      <ul className="list-disc columns-2 md:columns-3 mt-4">
        {props.options.map((option) => (
          <li key={option.name} className="text-left">
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const TemplateDetail = ({
  template,
  deleteTemplate,
  handleTogglePublish,
  templateId,
  library
}: TemplateDetailProps): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "";
  const tabs = ["description", "configuration"];
  const [selectedIndex, setSelectedIndex] = useState(Math.max(tabs.indexOf(tab), 0));
  const navigate = useNavigate();

  return (
    <div>
      <span
        onClick={() => navigate(-1)}
        className="font-bold text-sm cursor-pointer inline-flex"
        data-testid="backLink">
        <span className="text-primary mr-1">
          <ArrowLeft />
        </span>
        Back to templates
      </span>
      <div className="bg-white rounded-lg p-4 flex justify-between my-4">
        <div className="pt-6">
          <h4 className="font-semibold text-2xl mb-2">{template.name}</h4>
          <p className="text-sm font-semibold text-grey-60 mb-4">
            {`By: ${template.createdBy.name}`} | {`💚 ${template.installs}`}
          </p>
          <p className="font-semibold text-sm mb-2">
            Price: {template.price ? <Amount amount={+template.price} /> : "FREE"}
          </p>
          {library ? (
            <Link to={`/templates/library/${templateId}/purchase`}>
              <IconButton data-testid="" className="bg-gradient-to-r from-primary to-secondary">
                Use template
              </IconButton>
            </Link>
          ) : (
            <DropdownButton
              items={[
                { text: "Edit template details", link: `/templates/published/${templateId}/edit` },
                {
                  text: "Update configuration",
                  link: `/templates/published/${templateId}/variants`
                },
                {
                  text: template.status == "DRAFT" ? "Publish template" : "Unpublish template",
                  onClick: () => handleTogglePublish()
                },
                {
                  text: <span className="text-danger hover:text-white">Delete template</span>,
                  onClick: () => deleteTemplate.handleConfirmDelete(template)
                }
              ]}>
              {(props) => (
                <IconButton data-testid="updateTemplate" className="">
                  Update template <Caret {...props} />
                </IconButton>
              )}
            </DropdownButton>
          )}
        </div>
        <img
          src={
            template.images.find((image) => image.isDefault)?.thumbnailUrl ||
            "/images/thumbnail.jpg"
          }
          alt={template.name}
          className="w-[400px] h-40 rounded-lg object-contain object-right"
        />
      </div>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="bg-white flex text-grey-50 font-bold my-4">
          <TabTitle title="Description" isSelected={selectedIndex == 0} icon={<Extension />} />
          <TabTitle title="Configuration" isSelected={selectedIndex == 1} icon={<Customize />} />
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel>
            <div className="bg-white rounded-lg p-4 my-4 markdown markdown-table">
              {template.description ? (
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[
                    rehypeRaw,
                    [
                      rehypeSanitize,
                      {
                        tagNames: [...defaultSchema.tagNames!, "u"]
                      }
                    ]
                  ]}>
                  {template.description}
                </Markdown>
              ) : (
                <p className="text-grey-50 italic">No description provided</p>
              )}
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="bg-white rounded-lg p-4 my-4">
              <ConfigurationSection title="Variants" options={template.variants} />
              <ConfigurationSection title="Components" options={template.components} />
              <ConfigurationSection title="Add-ons" options={template.addOns} />
              <ConfigurationSection title="Activities" options={template.activities} />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <ConfirmationDialog
        message={deleteTemplate.deleteConfirmationMessage}
        handleContinue={deleteTemplate.handleDeleteConfirmation}
        handleCancel={deleteTemplate.handleDeleteCancellation}
      />
    </div>
  );
};
