import { useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { ERROR_OCCURRED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { getDate } from "helpers/date";
import { isNotEmpty, isNumber } from "helpers/validate";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormChangeEvent,
  Response,
  StoreProductForm,
  StoreItemsLoaders,
  UseStoreItemProductsType,
  ProductDetailVariant
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useStoreItemProducts = (
  isEmbedded?: boolean,
  closeModals?: () => void
): UseStoreItemProductsType => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const defaultStoreForm: StoreProductForm = {
    price: "",
    measurement: "",
    unit: "",
    productId: "",
    variantId: "",
    quantity: "1"
  };

  // UseStates
  const [storeProductForm, setStoreProductForm] = useState<StoreProductForm>(defaultStoreForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<StoreItemsLoaders>({
    savingStoreItem: false,
    fetchingStoreItems: true
  });
  const [error, setError] = useState("");

  const handleFormChange = (event: FormChangeEvent, variant?: ProductDetailVariant): void => {
    const { name, value } = event.target;
    const data: Partial<StoreProductForm> = {};

    if (name === "productId") {
      data.variantId = "";
    }
    if (name === "variantId" && variant) {
      data.price = variant.cost.total.toString();
    }
    setStoreProductForm((prev) => ({
      ...prev,
      ...data,
      [name]: value
    }));
    setError("");
  };

  const handleCreateStoreProductItem = async (): Promise<boolean> => {
    const requestData = {
      date: getDate(),
      unit: storeProductForm.unit,
      quantity: 1,
      measurement: +storeProductForm.measurement,
      type: "PRODUCT",
      variantId: storeProductForm.variantId
    };

    try {
      const json: Response<string> = await api.post("store", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      await queryClient.invalidateQueries({ queryKey: ["store_items"] });
      closeModals?.();
      setStoreProductForm(defaultStoreForm);
      if (isSuccessfull) {
        track(SegmentEvent.STORE_ITEM_ADDED, {
          product: storeProductForm.productId,
          variant: storeProductForm.variantId,
          measurement: storeProductForm.measurement,
          price: storeProductForm.price,
          quantity: storeProductForm.quantity,
          unit: storeProductForm.unit,
          type: "PRODUCT"
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingStoreItem: true }));
    const result = await handleCreateStoreProductItem();

    setLoaders((prev) => ({ ...prev, savingStoreItem: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      if (!isEmbedded) {
        navigate("/store");
      }
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(
      isNotEmpty(storeProductForm.productId) &&
        isNotEmpty(storeProductForm.variantId) &&
        isNumber(storeProductForm.measurement) &&
        isNumber(storeProductForm.quantity) &&
        isNotEmpty(storeProductForm.unit)
    );
  }, [storeProductForm]);

  return {
    storeProductForm,
    formIsValid,
    loaders,
    error,
    handleFormChange,
    handleFormSubmit,
    isEmbedded
  };
};

export default useStoreItemProducts;
