import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { FETCH_FAILED, REQUEST_SUCCESSFUL } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { extractErrorMessage } from "helpers/api";
import { getDate } from "helpers/date";
import { isNotEmpty, isNumber } from "helpers/validate";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormChangeEvent, MeasurementUnit, Response, StoreForm, StoreItems } from "types";
import { AlertType, SegmentEvent } from "types/enum";

const useStoreItems = (isEmbedded?: boolean, closeModals?: () => void) => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const defaultStoreForm: StoreForm = {
    name: "",
    price: "",
    measurement: "",
    unit: "",
    quantity: ""
  };

  // UseStates
  const [storeForm, setStoreForm] = useState<StoreForm>(defaultStoreForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [error, setError] = useState("");

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setStoreForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    createStoreItemMutation.mutate();
  };

  const createStoreItemMutation = useMutation({
    mutationFn: async () => {
      const requestData = {
        ...storeForm,
        date: getDate(),
        measurement: +storeForm.measurement,
        price: +storeForm.price,
        quantity: +storeForm.quantity,
        type: "RAW_MATERIAL"
      };

      const json: Response<string> = await api.post("store", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.STORE_ITEM_ADDED, {
          name: storeForm.name,
          measurement: storeForm.measurement,
          price: storeForm.price,
          quantity: storeForm.quantity,
          unit: storeForm.unit,
          type: "RAW_MATERIAL"
        });
        await queryClient.invalidateQueries({ queryKey: ["store_items"] });
        showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
        closeModals?.();
        setStoreForm(defaultStoreForm);
        if (!isEmbedded) {
          navigate("/store");
        }
      }
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const handleGetMeasurementUnits = async (): Promise<MeasurementUnit[]> => {
    try {
      const json: Response<MeasurementUnit[]> = await api.get("store/measurement-units").json();
      if (json.code === 200) {
        return json.data;
      }
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  const { data: measurementUnits = [] } = useQuery({
    queryKey: ["measurement_units"],
    queryFn: handleGetMeasurementUnits
  });

  const handleGetStoreItems = async (): Promise<StoreItems | undefined> => {
    try {
      const json: Response<StoreItems> = await api.get("store").json();
      if (json.code === 200) {
        return json.data;
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["store_items"],
    queryFn: handleGetStoreItems
  });

  // UseEffects
  useEffect(() => {
    setFormIsValid(
      isNotEmpty(storeForm.name) &&
        isNumber(storeForm.measurement) &&
        isNumber(storeForm.price) &&
        isNumber(storeForm.quantity) &&
        isNotEmpty(storeForm.unit)
    );
  }, [storeForm]);

  useEffect(() => {
    handleGetMeasurementUnits();
  }, []);

  return {
    storeForm,
    formIsValid,
    loaders: { fetchingStoreItems: isLoading, savingStoreItem: createStoreItemMutation.isPending },
    error,
    handleFormChange,
    handleFormSubmit,
    measurementUnits,
    storeItems: data,
    isEmbedded
  };
};

export default useStoreItems;

export type UseStoreItemsType = ReturnType<typeof useStoreItems>;
