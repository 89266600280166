import React, { SVGProps } from "react";

const Delete = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="mask0_243_1468" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_243_1468)">
        <path
          d="M6.08333 17.0833C5.66667 17.0833 5.3125 16.9375 5.02083 16.6458C4.72917 16.3541 4.58333 16 4.58333 15.5833V4.99998H3.75V3.74998H7.5V3.02081H12.5V3.74998H16.25V4.99998H15.4167V15.5833C15.4167 16 15.2708 16.3541 14.9792 16.6458C14.6875 16.9375 14.3333 17.0833 13.9167 17.0833H6.08333ZM14.1667 4.99998H5.83333V15.5833C5.83333 15.6528 5.85778 15.7119 5.90667 15.7608C5.955 15.8091 6.01389 15.8333 6.08333 15.8333H13.9167C13.9722 15.8333 14.0278 15.8055 14.0833 15.75C14.1389 15.6944 14.1667 15.6389 14.1667 15.5833V4.99998ZM7.83333 14.1666H9.08333V6.66665H7.83333V14.1666ZM10.9167 14.1666H12.1667V6.66665H10.9167V14.1666ZM5.83333 4.99998V15.8333V15.5833V4.99998Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Delete;
