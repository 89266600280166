import { useQuery } from "@tanstack/react-query";
import api from "config/api";
import { Response, TemplateSummary } from "types";

export const useTemplates = () => {
  const handleGetPublishedTemplates = async (): Promise<TemplateSummary | undefined> => {
    try {
      const json: Response<TemplateSummary> = await api.get("product-template/by/author").json();
      if (json.code === 200) {
        return json.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const purchasedTemplatesQuery = useQuery({
    queryKey: ["purchased_templates"],
    queryFn: handleGetPublishedTemplates
  });

  return {
    loaders: {
      fetchingTemplates: purchasedTemplatesQuery.isFetching
    },
    templateSummary: purchasedTemplatesQuery?.data
  };
};

export type UseTemplatesType = ReturnType<typeof useTemplates>;
