import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { FETCH_FAILED, REQUEST_SUCCESSFUL } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { extractErrorMessage } from "helpers/api";
import { useState } from "react";
import { AddOnForm, BusinessAddOn, FormChangeEvent, Response } from "types";
import { AlertType } from "types/enum";

export const useAddOns = (templateOrProductId?: string) => {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  // UseStates
  const [addOnForm, setaddOnForm] = useState<AddOnForm>({
    name: "",
    businessAddOnId: ""
  });
  const [error, setError] = useState("");

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setaddOnForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const saveProductAddOnMutation = useMutation({
    mutationFn: async () => {
      const requestData = {
        productId: templateOrProductId,
        name: addOnForm.name
      };

      const json: Response<string> = await api.post("addon", { json: requestData }).json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        await queryClient.invalidateQueries({ queryKey: ["business_addons"] });
        await queryClient.invalidateQueries({ queryKey: ["product_addons"] });
      }
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
      setaddOnForm({ name: "", businessAddOnId: "" });
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const saveTemplateAddOnMutation = useMutation({
    mutationFn: async () => {
      const requestData = {
        businessAddOnId: addOnForm.businessAddOnId
      };

      const json: Response<string> = await api
        .post("business-addons", { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        await queryClient.invalidateQueries({ queryKey: ["business_addons"] });
      }
      setaddOnForm({ name: "", businessAddOnId: "" });
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const handleProductFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setError("");
    saveProductAddOnMutation.mutate();
  };

  const handleTemplateFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    setError("");
    saveTemplateAddOnMutation.mutate();
  };

  const handleGetBusinessAddOns = async (): Promise<BusinessAddOn[]> => {
    try {
      const json: Response<BusinessAddOn[]> = await api.get("business-addons").json();
      if (json.code === 200) {
        return json.data;
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    return [];
  };

  const { data = [], isLoading } = useQuery({
    queryKey: ["business_addons"],
    queryFn: handleGetBusinessAddOns
  });

  return {
    businessAddOns: data,
    handleFormChange,
    handleProductFormSubmit,
    handleTemplateFormSubmit,
    error,
    loaders: {
      fetchingAddOns: isLoading,
      savingAddOns: saveProductAddOnMutation.isPending || saveTemplateAddOnMutation.isPending
    },
    addOnForm
  };
};

export type UseAddOnsType = ReturnType<typeof useAddOns>;
