import { useQuery } from "@tanstack/react-query";
import api from "config/api";
import { useAppState } from "config/store";
import { undefinedToString } from "helpers/string";
import { useSearch } from "hooks/shared/useSearch/useSearch";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BusinessType,
  Response,
  TemplateSummary,
  BusinessTypeCategory,
  TemplateSummaryItem
} from "types";

export const useTemplateLibrary = () => {
  const appState = useAppState();
  const business = appState.business.get();
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const businessType = searchParams.get("businessType");
  const businessCategory = searchParams.get("businessCategory");

  const searchProps = useSearch<BusinessTypeCategory, "name">(search || "");

  const [selectedBusinessType, setSelectedBusinessType] = useState(
    businessType || business?.businessType
  );
  const [selectedCategory, setSelectedCategory] = useState(businessCategory);

  const handleGetPublishedTemplates = async (): Promise<TemplateSummary | undefined> => {
    try {
      const json: Response<TemplateSummary> = await api.get("product-template/by/author").json();
      if (json.code === 200) {
        return json.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const purchasedTemplatesQuery = useQuery({
    queryKey: ["purchased_templates"],
    queryFn: handleGetPublishedTemplates
  });

  const handleGetBusinessTypes = async (): Promise<BusinessType[]> => {
    const json: Response<BusinessType[]> = await api.get("business-types").json();
    if (json.code === 200) {
      return json.data;
    }
    return [];
  };

  const businessTypesQuery = useQuery({
    queryKey: ["business_types"],
    queryFn: handleGetBusinessTypes
  });

  const handleGetBusinessTypeCategories = async (): Promise<BusinessTypeCategory[]> => {
    const json: Response<BusinessTypeCategory[]> = await api
      .get(`product-template/business-types/${selectedBusinessType}`)
      .json();
    if (json.code === 200) {
      return json.data;
    }
    return [];
  };

  const businessTypeCategoriesQuery = useQuery({
    queryKey: ["business_type_categories", selectedBusinessType],
    queryFn: handleGetBusinessTypeCategories,
    enabled: !!selectedBusinessType
  });

  const handleSearchProductTemplates = async (): Promise<TemplateSummaryItem[]> => {
    const json: Response<{ templates: TemplateSummaryItem[] }> = await api
      .get("product-template", {
        searchParams: {
          businessType: undefinedToString(selectedBusinessType),
          search: searchProps.search,
          category: undefinedToString(selectedCategory)
        }
      })
      .json();
    if (json.code === 200) {
      return json.data.templates;
    }
    return [];
  };

  const searchProductTemplatesQuery = useQuery({
    queryKey: ["product_templates", selectedBusinessType, searchProps.search, selectedCategory],
    queryFn: handleSearchProductTemplates
  });

  return {
    loaders: {
      fetchingTemplates:
        purchasedTemplatesQuery.isFetching || searchProductTemplatesQuery.isFetching,
      fetchingCategories: businessTypeCategoriesQuery.isFetching
    },
    templateSummary: purchasedTemplatesQuery?.data,
    businessTypes: businessTypesQuery?.data || [],
    businessTypeCategories: businessTypeCategoriesQuery?.data || [],
    productTemplates: searchProductTemplatesQuery?.data || [],
    selectedBusinessType,
    setSelectedBusinessType,
    search: searchProps,
    selectedCategory,
    setSelectedCategory
  };
};

export type UseTemplateLibraryType = ReturnType<typeof useTemplateLibrary>;
