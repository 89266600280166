import Amount from "components/Shared/Amount";
import DateFilter from "components/Shared/DateFilter";
import ArrowInward from "icons/ArrowInward";
import ArrowOutward from "icons/ArrowOutward";
import Wallet from "icons/Wallet";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults, Chart } from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import "chart.js/auto";
import { OverviewProps } from "types";
import Title from "components/Shared/Title";
import Rocket from "icons/Rocket";
import { Link } from "react-router-dom";
import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import Categories from "icons/Categories";
import CreditCard from "icons/CreditCard";
import Contact from "icons/Contact";
import cx from "classnames";
import { addYears, eachYearOfInterval, getYear } from "date-fns";
import Check from "icons/Check";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";

const Overview = (props: OverviewProps): React.ReactElement => {
  const { dateRange, handleDateRangeChange, report, yearlyReport, year, setYear } = props;
  const reportIsEmpty = report && report.income === 0 && report.revenue === 0;
  ChartJS.register(ArcElement, Tooltip, Legend);
  defaults.font.family = "'Manrope', 'Helvetica', sans-serif";
  defaults.font.size = 10;
  defaults.font.weight = "bolder";

  const getBreakdownPercentage = (value = 0): string => {
    if (!report) {
      return "0%";
    }
    const percentage =
      (value / (report!.expenses + report!.addOnCost + report!.materialsCost)) * 100;
    return `(${percentage.toFixed(2)}%)`;
  };

  const expensesData = {
    labels: [
      `Expenses ${getBreakdownPercentage(report?.expenses)}`,
      `Add-ons ${getBreakdownPercentage(report?.addOnCost)}`,
      `Components ${getBreakdownPercentage(report?.materialsCost)}`
    ],
    datasets: [
      {
        label: "",
        data: [report?.expenses, report?.addOnCost, report?.materialsCost],
        backgroundColor: [
          "#F87C22",
          "#50D37D",
          "#2251F8",
          "#AB31E4",
          "rgb(54, 162, 235)",
          "rgb(255, 99, 132)",
          "rgb(75, 192, 192)"
        ],
        hoverOffset: 4
      }
    ]
  };

  const incomeRevLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const incomeRevData = {
    labels: incomeRevLabels,
    datasets: [
      {
        label: "Net income",
        data: yearlyReport?.months.map((month) => month.income + month.due),
        backgroundColor: ["#00A89D"],
        borderWidth: 0,
        barPercentage: 1,
        categoryPercentage: 0.6
      },
      {
        label: "Revenue",
        data: yearlyReport?.months.map((month) => month.revenue),
        backgroundColor: ["#68757D"],
        borderWidth: 0,
        barPercentage: 1,
        categoryPercentage: 0.6
      }
    ]
  };

  const years = eachYearOfInterval({
    start: new Date("2022-01-01"),
    end: addYears(new Date(), 1)
  });

  const totalExpenses =
    (report?.expenses ?? 0) + (report?.addOnCost ?? 0) + (report?.materialsCost ?? 0);
  const maxRevOrExp = Math.max(report?.revenue || 0, totalExpenses);
  const maxIncOrZero = Math.max(report?.income || 0, 0);

  const expensesPercentage = report
    ? parseInt(((totalExpenses / maxRevOrExp) * 100).toString()) || 100
    : 100;
  const revenuePercentage = report
    ? parseInt(((report?.revenue / maxRevOrExp) * 100).toString()) || 0
    : 0;
  const incomePercentage = report
    ? parseInt(((maxIncOrZero / maxRevOrExp) * 100).toString()) || 0
    : 0;

  return (
    <div className="px-4 sm:px-0 pb-8">
      {reportIsEmpty && (
        <div className="space-y-4 mb-8">
          <div className="w-full text-white rounded-lg bg-gradient-to-r from-primary to-secondary p-3 md:p-6 md:py-5 flex justify-between gap-x-4">
            <div className="w-10 pr-2 md:pr-0 md:w-20 flex-none">
              <Rocket className="" />
            </div>
            <div>
              <p className="font-bold pb-2 text-xl md:text-3xl">You are almost set!</p>
              <p>
                Get started adding your records so you can get started tracking your business’s
                profitability. Start by adding expenses, then setup your products and start
                recording sales!
              </p>
              <div className="flex flex-wrap gap-x-4 md:gap-x-8 items-center">
                <Link to="/products/create" data-testid="createProduct" className="my-2 md:my-4">
                  <IconButton icon={<Plus />}>Add products/services</IconButton>
                </Link>
                <Link
                  to="/sales/orders/create"
                  data-testid="createProduct"
                  className="my-2 md:my-4">
                  {report?.orders === 0 ? (
                    <IconButton icon={<Plus />}>Add your orders</IconButton>
                  ) : (
                    <span className="flex gap-x-1">
                      <Check /> <span className="border-b font-bold">Add your orders</span>
                    </span>
                  )}
                </Link>
                <Link to="/expenses/create" data-testid="createExpense" className="my-2 md:my-4">
                  {report?.expenses === 0 ? (
                    <IconButton icon={<Plus />}>Add expenses</IconButton>
                  ) : (
                    <span className="flex gap-x-1">
                      <Check /> <span className="border-b font-bold">Add expenses</span>
                    </span>
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row items-start justify-between md:items-center space-y-4 md:space-y-0 pb-0 md:pb-6">
        <Title>{reportIsEmpty ? "Welcome," : "Overview"}</Title>
        <DateFilter
          dateRange={dateRange}
          handleDateRangeChange={(date): void => handleDateRangeChange(date)}
        />
      </div>
      <div>
        <div className="grid grid-cols-2 md:grid-cols-9 grid-rows-6 md:grid-rows-3 md:grid-flow-col gap-4">
          <div className="bg-primary text-white rounded-lg p-4 md:p-7 flex flex-col justify-center col-span-2 md:col-span-3 row-span-1">
            <div className="flex justify-end">
              <Wallet width={40} height={40} />
            </div>
            <div>
              <span className="text-sm mb-2 font-semibold">
                Income
                <InfoTooltip>
                  The profit or loss your business has recorded. Calculated by total revenue - total
                  spent
                </InfoTooltip>
              </span>
              <p className="font-bold text-2xl">
                <Amount amount={report?.income} />
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 md:p-7 flex flex-col justify-center col-span-2 md:col-span-3 row-span-1">
            <div className="flex justify-end text-primary">
              <ArrowInward />
            </div>
            <div>
              <span className="text-grey-50 text-sm mb-2 font-semibold">
                Revenue
                <InfoTooltip>
                  The total payments received from orders within this period.
                </InfoTooltip>
              </span>
              <p className="font-bold text-2xl">
                <Amount amount={report?.revenue} />
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 md:p-7 flex flex-col justify-center col-span-2 md:col-span-3 row-span-1">
            <div className="flex justify-end text-primary">
              <ArrowOutward />
            </div>
            <div>
              <span className="text-grey-50 text-sm mb-2 font-semibold">
                Total spent
                <InfoTooltip>
                  The total cost incurred by your business. It is the sum of Expenses incurred as
                  well as components and add-ons used to produce orders
                </InfoTooltip>
              </span>
              <p className="font-bold text-2xl">
                <Amount amount={report?.totalSpent} />
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 pr-7 md:pr-12 md:p-7 !pb-12 flex flex-col justify-between col-span-2 md:col-span-6 row-span-1">
            <div className="flex space-x-4 text-grey-60 text-[13px]">
              <span className="flex items-center">
                <span className="inline-flex w-2.5 h-2.5 rounded-full bg-grey-50 mr-1"></span>
                <small>Revenue</small>
              </span>
              <span className="flex items-center">
                <span className="inline-flex w-2.5 h-2.5 rounded-full bg-primary mr-1"></span>
                <small>Profit</small>
              </span>
              <span className="grow text-end">
                <InfoTooltip>Track how close your business is to profitability</InfoTooltip>
              </span>
            </div>
            <div className="w-full rounded-md border-[1px] border-grey-30 h-10 p-1 flex justify-between relative">
              <div
                className={cx(
                  "bg-grey-50 h-full",
                  maxIncOrZero > 0 ? "rounded-l-md" : "rounded-md"
                )}
                style={{
                  width: `${maxIncOrZero > 0 ? expensesPercentage : revenuePercentage}%`
                }}></div>
              <div
                data-content="Break even"
                className="rounded-r-md relative bg-primary h-full before:content-[attr(data-content)] before:absolute before:top-full before:text-sm before:pt-2 before:-left-8 before:min-w-[5rem] after:content-[''] after:w-0 after:border-l-[1px] after:h-12 after:absolute after:border-dark-grey after:left-0 after:-top-2"
                style={{
                  width: `${incomePercentage > 0 ? incomePercentage + 1 : incomePercentage}%`
                }}></div>
              <small
                className={cx(
                  "absolute bottom-[-40px] text-danger text-[70%]",
                  revenuePercentage < 100 && "text-danger",
                  revenuePercentage >= 100 && "text-primary"
                )}
                style={{
                  right: `calc(${
                    incomePercentage > 0 ? incomePercentage + 1 : incomePercentage
                  }% - 20px)`
                }}>
                ({revenuePercentage >= 100 && "+"}
                {(expensesPercentage - revenuePercentage) * -1}%)
              </small>
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 md:p-7 col-span-2 md:col-span-4 row-span-2">
            <span className="text-sm font-semibold mb-4 inline-flex">
              Breakdown for total spent
            </span>
            <div className="h-[300px] flex justify-center">
              <Doughnut
                data={expensesData}
                datasetIdKey="expenses"
                options={{
                  cutout: "70%",
                  borderColor: "transparent",
                  plugins: {
                    legend: {
                      position: "bottom",
                      labels: {
                        boxWidth: 8,
                        boxHeight: 8,
                        usePointStyle: true,
                        padding: 14
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 md:p-7 flex md:flex-col col-span-2 row-span-1 md:row-span-2 row-start-7 md:row-start-2">
            <div className="w-full h-full flex flex-col justify-between border-r md:border-r-0 md:border-b border-grey-30 pr-4 md:pr-0 md:pb-4">
              <div className="flex justify-end text-primary">
                <CreditCard width={40} height={40} />
              </div>
              <div>
                <span className="text-grey-50 text-sm mb-2 font-semibold">
                  Items ordered
                  <InfoTooltip>
                    Comprises of all the products ordered. Order units are the order items *
                    quantity of each item
                  </InfoTooltip>
                </span>
                <p className="font-bold text-2xl">
                  {report?.orders} / {report?.totalOrderItemUnits}
                </p>
              </div>
            </div>
            <div className="w-full h-full flex flex-col justify-between pl-4 md:pl-0 md:pt-4">
              <div className="flex justify-end text-primary">
                <Contact />
              </div>
              <div>
                <span className="text-grey-50 text-sm mb-2 font-semibold">
                  Active customers
                  <InfoTooltip>Number of customers that made orders in this period</InfoTooltip>
                </span>
                <p className="font-bold text-2xl">{report?.activeCustomers}</p>
              </div>
            </div>
          </div>
        </div>
        <hr className="flex-1 h-0.5 my-4 border-grey-30" />
        <div className="w-full h-72 md:h-96 bg-white rounded-lg p-4 md:p-7 mb-4 relative">
          <select
            className="text-primary border-0 text-sm absolute right-2 md:right-4 focus:ring-0"
            name="year"
            id="year"
            value={year}
            onChange={(e): void => setYear(e.target.value)}>
            {years.map((year) => (
              <option value={getYear(year)} key={getYear(year)}>
                {getYear(year)}
              </option>
            ))}
          </select>
          <Bar
            data={incomeRevData}
            datasetIdKey="incomeRev"
            plugins={[
              {
                id: "increase-legend-spacing",
                beforeInit(chart: Chart<"bar">) {
                  // eslint-disable-next-line
                  const originalFit = (chart.legend as any).fit;
                  // eslint-disable-next-line
                  (chart.legend as any).fit = function fit() {
                    // Call original function and bind scope in order to use `this` correctly inside it
                    originalFit.bind(chart.legend)();
                    this.height += 20;
                  };
                }
              }
            ]}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              borderColor: "transparent",
              plugins: {
                legend: {
                  position: "top",
                  align: "start",
                  labels: {
                    boxWidth: 8,
                    boxHeight: 8,
                    usePointStyle: true,
                    padding: 14
                  }
                }
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  },
                  border: {
                    display: false
                  }
                },
                y: {
                  grid: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.05)"
                  },
                  border: {
                    display: false
                  },
                  ticks: {
                    callback: function (value) {
                      return +value < 1000000 ? +value / 1000 + "K" : +value / 1000000 + "M";
                    }
                  }
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
