import { CreateEditProductOfferingProps } from "types";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Title from "components/Shared/Title";
import Stepper from "components/Shared/Stepper";
import { InlineInput } from "components/Shared/InlineInput/InlineInput";
import Error from "components/Shared/Error";
import Form from "components/Shared/Form";
import Button from "components/Shared/Button";
import { ProductItem } from "components/CreateEditOrder/Products/Products";
import TextInput from "components/Shared/TextInput";
import Plus from "icons/Plus";
import { DropdownButton } from "components/Shared/DropdownButton/DropdownButton";
import SubTitle from "components/Shared/SubTitle/SubTitle";
import IconButton from "components/Shared/IconButton/IconButton";
import Amount from "components/Shared/Amount";
import { getOptionTotal } from "./offerings.utils";
import Dropdown from "components/Shared/Dropdown";
import Edit from "icons/Edit";
import Copy from "icons/Copy";
import Delete from "icons/Delete";
import Kebeb from "icons/Kebeb";

export const CreateEditProductOffering = (
  props: CreateEditProductOfferingProps
): React.ReactElement => {
  const {
    offeringId,
    handleFormChange,
    productOfferingForm,
    optionForm,
    error,
    loaders,
    formIsValid,
    handleOptionNameChange,
    products,
    handleOptionItemChange,
    handleRemoveItem,
    handleAddItem,
    handleFormSubmit,
    handleDuplicateOption,
    handleRemoveOption,
    handleSaveOption,
    handleUpdateOption
  } = props;
  const isCreatingNewOffering = !offeringId;
  const [activeStep, setActiveStep] = useState("Setup options");

  return (
    <div className="bg-white rounded-lg py-form mobile-full-screen">
      <div className="form-xy pb-0">
        <Link
          to="/sales/shop-front"
          className="font-bold text-sm inline-flex"
          data-testid="backLink">
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to shopfront
        </Link>
        <Title className="my-8 text-center">
          {isCreatingNewOffering ? "Create" : "Edit"} product offering
        </Title>
        <div>
          <Stepper
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={["Setup options", "Add images", "Extras"]}
          />
        </div>
        <SubTitle className="pt-6 text-grey-50">
          Setup the options that are available for this product offering
        </SubTitle>
      </div>
      <div className="px-4 md:px-10">
        <Error error={error} />
      </div>
      <div className="overflow-hidden max-w-[100%] my-4 px-4 lg:px-10">
        <span className="flex items-center">
          <InlineInput
            name="name"
            id="offeringName"
            value={productOfferingForm.name}
            onChange={handleFormChange}
            placeholder="Offering name *"
            className="text-lg md:text-xl"
          />
        </span>
      </div>
      {activeStep === "Setup options" && (
        <div>
          <div className="">
            <div className="justify-between hidden sm:flex form-x py-3 sm:pr-20 bg-soft-grey border-b">
              <p className="text-grey-50 font-bold">Details</p>
              <p className="text-grey-50 font-bold">Amount</p>
            </div>
            <div data-testid="products">
              <div
                className="relative form-x sm:pr-20 focus-within:bg-soft-grey pt-4 pb-2 "
                data-testid="option">
                <div>
                  <div className="w-full sm:w-4/5 flex justify-between pb-4 items-center">
                    <span className="flex items-center overflow-hidden max-w-[70%]">
                      <InlineInput
                        padSize={0}
                        defaultSize={13}
                        name="name"
                        id="name"
                        value={optionForm.name}
                        onChange={(e): void => {
                          handleOptionNameChange(e);
                        }}
                        placeholder="Untitled option *"
                      />
                    </span>
                  </div>
                  {optionForm.items.map((item, itemIndex) => (
                    <ProductItem
                      key={`${itemIndex}${item.variantId}`}
                      isInGroup
                      products={products}
                      product={item}
                      handleFormProductChange={(e): void => {
                        handleOptionItemChange(
                          e,
                          itemIndex,
                          products.find(({ id }) => item.productId === id),
                          products
                            .find(({ id }) => item.productId === id)
                            ?.variants.find(({ id }) => e.target.value === id)
                        );
                      }}
                      handleFormProductVariantChange={(e): void => {
                        handleOptionItemChange(
                          e,
                          itemIndex,
                          products.find(({ id }) => item.productId === id),
                          products
                            .find(({ id }) => item.productId === id)
                            ?.variants.find(({ id }) => e.target.value === id)
                        );
                      }}
                      handleRemoveProduct={(): void => handleRemoveItem(itemIndex)}
                      disabledRemove={optionForm.items.length == 1}
                    />
                  ))}
                </div>
              </div>
              <div className="my-4 px-10 w-full flex justify-end items-center">
                <button
                  type="button"
                  title="Add product"
                  className="text-primary pl-2 cursor-pointer flex gap-1"
                  onClick={(): void => {
                    handleAddItem();
                  }}>
                  <Plus height="20" width="20" />
                  Add Item
                </button>
                {/* <button
                          type="button"
                          title="Duplicate group"
                          className="text-primary pl-2 cursor-pointer"
                          onClick={(): void => {
                            handleDuplicateGroupedProduct(index);
                          }}>
                          <Copy />
                        </button> */}
                {/* <button
                          type="button"
                          title="Duplicate group"
                          className="text-danger pl-2 cursor-pointer"
                          onClick={(): void => {
                            handleRemoveProduct(index);
                          }}>
                          <Delete />
                        </button> */}
              </div>
              <div className="my-4 px-10 flex flex-col justify-end items-end gap-2">
                <span className="text-xl font-semibold">
                  <span className="">Total: </span>
                  <Amount amount={getOptionTotal(optionForm)} />
                </span>

                <IconButton
                  size="sm"
                  icon={<Plus />}
                  data-testid="createVariant"
                  outlined
                  disabled={!formIsValid}
                  onClick={handleSaveOption}>
                  Set option
                </IconButton>
              </div>
            </div>
          </div>
          <div className="border-soft-grey border-t form-xy">
            <h4 className="font-semibold text-2xl mb-4">Options</h4>
            {productOfferingForm.options.map((option, index) => (
              <div
                className="flex justify-between bg-soft-grey rounded-lg px-6 py-4 mb-2"
                key={index}
                data-testid="option">
                <div>
                  <p className="font-bold text-lg" data-testid="">
                    {option.name}
                  </p>
                  <p className="font-bold text-sm" data-testid="">
                    {option.items.map((item) => `${item.name} (x${item.quantity})`).join(", ")}
                  </p>
                </div>

                <div className="flex">
                  <Amount amount={getOptionTotal(option)} />
                  <Dropdown
                    menu={[
                      {
                        text: "Edit",
                        icon: <Edit />,
                        onClick: () => handleUpdateOption(index)
                      },
                      {
                        text: "Duplicate",
                        icon: <Copy />,
                        onClick: () => handleDuplicateOption(index)
                      },
                      {
                        text: "Delete",
                        icon: <Delete />,
                        onClick: () => handleRemoveOption(index)
                      }
                    ]}>
                    <Kebeb />
                  </Dropdown>
                </div>
              </div>
            ))}
          </div>
          <Form.Footer center>
            {isCreatingNewOffering ? (
              <Button
                loading={loaders.savingOffering}
                type="submit"
                disabled={
                  loaders.savingOffering ||
                  productOfferingForm.options.length == 0 ||
                  !productOfferingForm.name
                }
                className="w-full md:w-60"
                data-testid="continue"
                onClick={handleFormSubmit}>
                Create
              </Button>
            ) : (
              <Button
                loading={loaders.savingOffering}
                type="submit"
                disabled={
                  loaders.savingOffering ||
                  productOfferingForm.options.length == 0 ||
                  !productOfferingForm.name
                }
                className="w-full md:w-60"
                onClick={handleFormSubmit}
                data-testid="continue">
                Save
              </Button>
            )}
          </Form.Footer>
        </div>
      )}
    </div>
  );
};
