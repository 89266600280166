import { useQuery } from "@tanstack/react-query";
import api from "config/api";
import { useParams } from "react-router-dom";
import { PurchasedTemplateSummary, Response } from "types";

export const useTemplatePurchases = () => {
  const { templateId } = useParams();

  const purchasedTemplateQuery = useQuery({
    queryKey: ["purchased_templates"],
    queryFn: async () => {
      const json: Response<PurchasedTemplateSummary> = await api
        .get("product-template/business/purchases")
        .json();
      return json.data;
    }
  });

  return {
    loaders: {
      fetchingPurchases: purchasedTemplateQuery.isFetching
    },
    templateId,
    templateSummary: purchasedTemplateQuery.data
  };
};

export type UseTemplatePurchasesType = ReturnType<typeof useTemplatePurchases>;
