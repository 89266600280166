import Button from "components/Shared/Button";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import Loading from "components/Shared/Loading";
import { SearchInput } from "components/Shared/SearchInput/SearchInput";
import Select from "components/Shared/Select";
import Title from "components/Shared/Title/Title";
import React from "react";
import { Link } from "react-router-dom";
import { TemplateLibraryCategoryProps } from "types";

export const TemplateLibraryCategory = (
  props: TemplateLibraryCategoryProps
): React.ReactElement => {
  const {
    businessTypeCategories,
    businessTypes,
    selectedBusinessType,
    setSelectedBusinessType,
    search,
    loaders
  } = props;
  return (
    <div>
      <Title className="pt-2 pb-6 px-4 md:px-0">
        Product Templates
        <InfoTooltip>These are the templates available for you.</InfoTooltip>
      </Title>
      <div className="block sm:flex flex-col-reverse sm:flex-row justify-between items-start sm:items-center px-4 md:px-0">
        <div className="flex md:gap-4 items-center sm:flex-nowrap flex-wrap gap-x-2 gap-y-0">
          <div className="w-[50%] sm:w-40">
            <Select
              simple
              inputSize="sm"
              name="businessType"
              label=""
              defaultValue=""
              value={selectedBusinessType}
              onChange={(e) => setSelectedBusinessType(e.target.value)}>
              <option value="">Business Type</option>
              {businessTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <Loading loading={loaders.fetchingCategories}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 bg-white lg:p-8 p-4 rounded-lg  mt-2">
          <div className="text-center col-span-1 sm:col-span-2 md:col-span-3">
            <h4 className="font-semibold text-lg">Find a template to add as a product</h4>
          </div>
          <div className="col-span-1 sm:col-span-2 md:col-span-3 flex justify-center ">
            <div className="w-full sm:w-1/2 flex gap-4 justify-center items-start">
              <div className="flex-grow">
                <SearchInput inputSize="md" {...search} />
              </div>
              {search.search && (
                <Link
                  to={`/templates/library/search?businessType=${selectedBusinessType}&search=${search.search}`}
                  className="cursor-pointer">
                  <Button>Search</Button>
                </Link>
              )}
            </div>
          </div>
          <div className="col-span-1 sm:col-span-2 md:col-span-3">
            <h4 className="font-semibold text-lg text-grey-50">View templates by categories</h4>
          </div>

          {businessTypeCategories.map((category) => (
            <Link
              key={category.id}
              to={`/templates/library/search?businessType=${selectedBusinessType}&businessCategory=${category.id}`}
              className="cursor-pointer">
              <div className="h-40 w-full relative">
                <img
                  src={category.imageUrl || "/images/thumbnail.jpg"}
                  alt={category.name}
                  className="w-full h-full rounded-lg object-cover"
                  onError={(e) => {
                    e.currentTarget.onerror = null; // Prevents infinite loop
                    e.currentTarget.src = "/images/thumbnail.jpg";
                  }}
                />
              </div>
              <div className="flex justify-between py-4">
                <div>
                  <p className="text-sm md:text-base font-semibold">{category.name}</p>
                </div>
                <p className="whitespace-nowrap">
                  <span className="left-3 bottom-3 text-grey-60 px-2 py-1 rounded bg-soft-grey font-medium text-xs">
                    {category.noOfTemplates || 0} Templates
                  </span>
                </p>
              </div>
            </Link>
          ))}
          {businessTypeCategories.length == 0 && (
            <div data-testid="" className="col-span-1 sm:col-span-2 md:col-span-3">
              No categories present for this business type
            </div>
          )}
        </div>
      </Loading>
    </div>
  );
};
