import React from "react";

export const NavPublishedTemplate = (): React.ReactElement => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_3234_5382" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="22">
      <rect y="0.478516" width="21" height="21" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3234_5382)">
      <path
        d="M9.625 17.9785V10.8473L7.35 13.1223L6.125 11.8535L10.5 7.47852L14.875 11.8535L13.65 13.1223L11.375 10.8473V17.9785H9.625ZM3.5 8.35352V5.72852C3.5 5.24727 3.67135 4.83529 4.01406 4.49258C4.35677 4.14987 4.76875 3.97852 5.25 3.97852H15.75C16.2312 3.97852 16.6432 4.14987 16.9859 4.49258C17.3286 4.83529 17.5 5.24727 17.5 5.72852V8.35352H15.75V5.72852H5.25V8.35352H3.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
