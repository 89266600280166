import React, { SVGProps } from "react";

const Edit = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="mask0_243_1463" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_243_1463)">
        <path
          d="M4.41699 16.8333C4.00033 16.8333 3.64616 16.6875 3.35449 16.3958C3.06283 16.1041 2.91699 15.75 2.91699 15.3333V4.16663C2.91699 3.74996 3.06283 3.39579 3.35449 3.10413C3.64616 2.81246 4.00033 2.66663 4.41699 2.66663H11.542L10.292 3.91663H4.41699C4.36144 3.91663 4.30588 3.9444 4.25033 3.99996C4.19477 4.05551 4.16699 4.11107 4.16699 4.16663V15.3333C4.16699 15.3888 4.19477 15.4444 4.25033 15.5C4.30588 15.5555 4.36144 15.5833 4.41699 15.5833H15.5837C15.6392 15.5833 15.6948 15.5555 15.7503 15.5C15.8059 15.4444 15.8337 15.3888 15.8337 15.3333V9.43746L17.0837 8.18746V15.3333C17.0837 15.75 16.9378 16.1041 16.6462 16.3958C16.3545 16.6875 16.0003 16.8333 15.5837 16.8333H4.41699ZM13.7712 3.02079L14.667 3.89579L9.16699 9.39579V10.5833H10.3337L15.8753 5.06246L16.7503 5.93746L10.8753 11.8333H7.91699V8.87496L13.7712 3.02079ZM16.7503 5.93746L13.7712 3.02079L15.6878 1.10413C15.9795 0.812459 16.3373 0.666626 16.7612 0.666626C17.1845 0.666626 17.542 0.819404 17.8337 1.12496L18.6462 1.93746C18.9239 2.22913 19.0628 2.58329 19.0628 2.99996C19.0628 3.41663 18.917 3.77079 18.6253 4.06246L16.7503 5.93746Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Edit;
