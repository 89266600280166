import React, { SVGProps } from "react";

const Plus = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_199_183" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
        <rect width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_199_183)">
        <path
          d="M14.0625 20.9375H15.9375V15.9375H20.9375V14.0625H15.9375V9.0625H14.0625V14.0625H9.0625V15.9375H14.0625V20.9375ZM15 26.875C13.3542 26.875 11.8075 26.5625 10.36 25.9375C8.91167 25.3125 7.65625 24.4688 6.59375 23.4062C5.53125 22.3438 4.6875 21.0883 4.0625 19.64C3.4375 18.1925 3.125 16.6458 3.125 15C3.125 13.3542 3.4375 11.8071 4.0625 10.3587C4.6875 8.91125 5.53125 7.65625 6.59375 6.59375C7.65625 5.53125 8.91167 4.6875 10.36 4.0625C11.8075 3.4375 13.3542 3.125 15 3.125C16.6458 3.125 18.1929 3.4375 19.6413 4.0625C21.0888 4.6875 22.3438 5.53125 23.4062 6.59375C24.4688 7.65625 25.3125 8.91125 25.9375 10.3587C26.5625 11.8071 26.875 13.3542 26.875 15C26.875 16.6458 26.5625 18.1925 25.9375 19.64C25.3125 21.0883 24.4688 22.3438 23.4062 23.4062C22.3438 24.4688 21.0888 25.3125 19.6413 25.9375C18.1929 26.5625 16.6458 26.875 15 26.875ZM15 25C17.7917 25 20.1562 24.0312 22.0938 22.0938C24.0312 20.1562 25 17.7917 25 15C25 12.2083 24.0312 9.84375 22.0938 7.90625C20.1562 5.96875 17.7917 5 15 5C12.2083 5 9.84375 5.96875 7.90625 7.90625C5.96875 9.84375 5 12.2083 5 15C5 17.7917 5.96875 20.1562 7.90625 22.0938C9.84375 24.0312 12.2083 25 15 25Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Plus;
