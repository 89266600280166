import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { AUTOSAVE_INTERVAL } from "constants/general";
import {
  REQUEST_SUCCESSFUL,
  TEMPLATE_COMPONENT_ATTACHED,
  TEMPLATE_COMPONENT_DETACHED
} from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import deepEqual from "deep-equal";
import { track } from "helpers/analytics";
import { extractErrorMessage } from "helpers/api";
import { updateProperty } from "helpers/object";
import { useEffect, useMemo, useState } from "react";
import { useAutosave } from "react-autosave";
import { useParams } from "react-router-dom";
import {
  ComponentForm,
  FormChangeEvent,
  ObjectChanges,
  Response,
  TemplateComponentVariant,
  TemplateVariant
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useTemplateComponent = (templateVariants: TemplateVariant[]) => {
  const { templateId } = useParams();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  // UseStates
  const [componentForm, setComponentForm] = useState<ComponentForm>({
    measurement: "",
    unit: ""
  });
  const [variants, setVariants] = useState<TemplateComponentVariant[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [error, setError] = useState("");
  const [variantsHaveChanges, setVariantsHaveChanges] = useState<ObjectChanges>({});
  const savedVariants = useMemo<TemplateComponentVariant[]>(
    () =>
      templateVariants.map((variant) => ({
        variantId: variant.variantId,
        variantName: variant.name,
        variantMeasurements: variant.componentMeasurements
      })),
    [templateVariants]
  );

  useAutosave({
    data: variants,
    onSave: (): void => {
      if (hasUnsavedChanges) {
        handleSaveComponentMeasurements();
      }
    },
    interval: AUTOSAVE_INTERVAL,
    saveOnUnmount: true
  });

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setComponentForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleVariantComponentsChange = (
    event: FormChangeEvent,
    variantId: string,
    componentId: string
  ): void => {
    const { name, value } = event.target;
    setVariants((prev) => {
      return prev.map((variant) => {
        const variantMeasurements = variant.variantMeasurements.map((part) =>
          updateProperty(part.componentId === componentId, part, { [name]: value })
        );
        return updateProperty(variant.variantId === variantId, variant, { variantMeasurements });
      });
    });
    setError("");
  };

  const handleAttachComponent = async (storeItemId: string): Promise<boolean> => {
    setError("");
    try {
      return await attachComponentMutation.mutateAsync(storeItemId);
    } catch {
      return false;
    }
  };

  const handleDetachComponent = async (componentId: string): Promise<boolean> => {
    setError("");
    try {
      return await detachComponentMutation.mutateAsync(componentId);
    } catch {
      return false;
    }
  };

  const handleSaveComponentMeasurements = (): void => {
    saveMeasurementsMutation.mutate(variants);
  };

  const saveMeasurementsMutation = useMutation({
    mutationFn: async (measurements: TemplateComponentVariant[]) => {
      const requestData = {
        variants: measurements.map(({ variantId, variantMeasurements }) => ({
          variantId,
          measurements: variantMeasurements
        }))
      };

      const json: Response<string> = await api
        .put(`product-template/${templateId}/component-measurements`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        await queryClient.invalidateQueries({ queryKey: ["template_configurations"] });
        await queryClient.invalidateQueries({ queryKey: ["template"] });
      }
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const attachComponentMutation = useMutation({
    mutationFn: async (storeItemId: string) => {
      const requestData = {
        templateId,
        storeItemId
      };

      const json: Response<string> = await api
        .post("product-template-component", { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_COMPONENT_ATTACHED, {
          templateId,
          storeItemId
        });
        await queryClient.invalidateQueries({ queryKey: ["template_configurations"] });
        await queryClient.invalidateQueries({ queryKey: ["template"] });
      }
      showAlert(AlertType.SUCCESS, TEMPLATE_COMPONENT_ATTACHED);
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const detachComponentMutation = useMutation({
    mutationFn: async (componentId: string) => {
      const json: Response<void> = await api
        .delete(`product-template-component/${componentId}`)
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_COMPONENT_DETACHED, {
          templateId,
          componentId
        });
        showAlert(AlertType.SUCCESS, TEMPLATE_COMPONENT_DETACHED);
        await queryClient.invalidateQueries({ queryKey: ["template_configurations"] });
        await queryClient.invalidateQueries({ queryKey: ["template"] });
      }
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setError("");
    handleSaveComponentMeasurements();
  };

  // UseEffects
  useEffect(() => {
    setHasUnsavedChanges(!deepEqual(variants, savedVariants));
    const variantsHaveChanges: ObjectChanges = {};
    variants.forEach((variant) => {
      variantsHaveChanges[variant.variantId] = !deepEqual(
        variant,
        savedVariants.find((savedVariant) => savedVariant.variantId == variant.variantId)
      );
    });
    setVariantsHaveChanges(variantsHaveChanges);
  }, [savedVariants, variants]);

  useEffect(() => {
    // Get variant formulas
    setVariants((prev) => {
      return templateVariants.map((variant) => {
        const existingVariant = prev.find(
          (oldVariant) => oldVariant.variantId === variant.variantId
        );
        const formatedVariant: TemplateComponentVariant = {
          variantId: variant.variantId,
          variantName: variant.name,
          variantMeasurements: variant.componentMeasurements
        };
        return updateProperty(!!existingVariant, formatedVariant, {
          ...formatedVariant,
          variantMeasurements: variant.componentMeasurements.map((measurement) => {
            const existingMeasurement = existingVariant?.variantMeasurements.find(
              (oldMeasurement) => oldMeasurement.componentId == measurement.componentId
            );
            return !existingMeasurement
              ? measurement
              : {
                  ...measurement,
                  measurementValue: +existingMeasurement.measurementValue,
                  measurementUnit: existingMeasurement.measurementUnit
                };
          })
        });
      });
    });
  }, [templateVariants]);

  return {
    componentForm,
    handleFormChange,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    handleVariantComponentsChange,
    templateId,
    handleAttachComponent,
    handleDetachComponent,
    loaders: {
      savingComponents:
        saveMeasurementsMutation.isPending &&
        attachComponentMutation.isPending &&
        detachComponentMutation.isPending,
      fetchingComponents: false
    },
    variants,
    variantsHaveChanges
  };
};

export type UseTemplateComponentType = ReturnType<typeof useTemplateComponent>;
