import CreateStoreItemProduct from "components/CreateStoreItemProduct";
import LoadingComponent from "components/Shared/Loading";
import { useProductsWithVariants } from "hooks/useProduct/useProductsWithVariants";
import useStoreItemProducts from "hooks/useStoreItemProducts";
import useStoreItems from "hooks/useStoreItems";
import React from "react";

const CreateStoreItemPage = (): React.ReactElement => {
  const { measurementUnits } = useStoreItems();
  const props = useStoreItemProducts();
  const { productsWithVariants, loaders } = useProductsWithVariants();

  return (
    <LoadingComponent loading={loaders.fetchingProduct}>
      <CreateStoreItemProduct
        {...props}
        measurementUnits={measurementUnits}
        products={productsWithVariants}
      />
    </LoadingComponent>
  );
};

export default CreateStoreItemPage;
