import { useMutation, useQuery } from "@tanstack/react-query";
import api from "config/api";
import { REQUEST_SUCCESSFUL } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { extractErrorMessage } from "helpers/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Response } from "types";
import { AlertType } from "types/enum";

export const useTemplatePurchase = () => {
  const { templateId } = useParams();
  const { showAlert } = useAlert();

  const [successful, setSuccessful] = useState(false);

  const purchaseTemplateQuery = useQuery({
    queryKey: ["purchased_template"],
    queryFn: async () => {
      const json: Response<{ isPurchased: boolean }> = await api
        .get(`product-template/${templateId}/purchase`)
        .json();
      const isSuccessfull = json.code === 200;
      setSuccessful(isSuccessfull);
      return isSuccessfull ? json.data : undefined;
    }
  });

  const purchaseTemplateMutation = useMutation({
    mutationFn: async () => {
      const json: Response<string> = await api
        .post(`product-template/${templateId}/purchase`, { json: {} })
        .json();
      const isSuccessfull = json.code === 201;
      setSuccessful(isSuccessfull);
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  useEffect(() => {
    if (!purchaseTemplateQuery.data?.isPurchased && !purchaseTemplateQuery.isFetching) {
      purchaseTemplateMutation.mutate();
    }
  }, [templateId, purchaseTemplateQuery.data, purchaseTemplateQuery.isFetching]);

  return {
    loaders: {
      purchasingTemplate: purchaseTemplateMutation.isPending,
      fetchingPurchase: purchaseTemplateQuery.isFetching
    },
    templateId,
    successful
  };
};

export type UseTemplatePurchaseType = ReturnType<typeof useTemplatePurchase>;
