import React from "react";
import { TemplateLibrary } from "components/TemplateLibrary/TemplateLibrary";
import { useTemplateLibrary } from "hooks/useTemplateLibrary/useTemplateLibrary";

const TemplateLibrarySearchPage = (): React.ReactElement => {
  const templateLibraryProps = useTemplateLibrary();

  return <TemplateLibrary {...templateLibraryProps} />;
};

export default TemplateLibrarySearchPage;
