import React from "react";
import { CreateStoreProductProps } from "types";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Select from "components/Shared/Select";
import Title from "components/Shared/Title";
import Amount from "components/Shared/Amount/Amount";
import InputGroup from "components/Shared/InputGroup/InputGroup";
import { capitalize } from "helpers/string";
import CurrencySymbol from "components/Shared/CurrencySymbol/CurrencySymbol";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import cx from "classnames";

const CreateStoreItemProduct = (props: CreateStoreProductProps): React.ReactElement => {
  const {
    storeProductForm,
    handleFormChange,
    handleFormSubmit,
    formIsValid,
    error,
    measurementUnits,
    loaders,
    products,
    isEmbedded,
    excludedProductId
  } = props;
  return (
    <div className={cx("bg-white rounded-lg p-4 lg:p-10", !isEmbedded && "mobile-full-screen")}>
      {!isEmbedded && (
        <>
          <Link to="/store" className="font-bold text-sm inline-flex" data-testid="backLink">
            <span className="text-primary mr-1">
              <ArrowLeft />
            </span>
            Back to store items
          </Link>
          <Title className="text-center mt-6 md:mt-0">
            Add from a product
            <InfoTooltip>
              Product store items are created from your existing products. They are products used as
              components to create other products
            </InfoTooltip>
          </Title>
        </>
      )}
      <form
        onSubmit={handleFormSubmit}
        className={cx("md:mx-12", isEmbedded ? "mt-1 xl:mx-20" : "mt-10 xl:mx-40")}
        data-testid="storeItemForm">
        <Error error={error} />
        <div>
          <Select
            name="productId"
            onChange={handleFormChange}
            value={storeProductForm.productId}
            required
            label="Select product"
            data-testid="productId">
            <option value="" disabled>
              Select product *
            </option>
            {products
              .filter((product) => product.id != excludedProductId)
              .map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
          </Select>
        </div>
        <div>
          <Select
            name="variantId"
            onChange={(e) =>
              handleFormChange(
                e,
                products
                  .find(({ id }) => storeProductForm.productId === id)
                  ?.variants.find(({ id }) => e.target.value === id)
              )
            }
            value={storeProductForm.variantId}
            required
            label="Select variant"
            data-testid="variantId">
            <option value="" disabled>
              Select variant *
            </option>
            {products
              .find(({ id }) => storeProductForm.productId === id)
              ?.variants.map((variant) => (
                <option key={variant.id} value={variant.id}>
                  {variant.name}
                </option>
              ))}
          </Select>
        </div>
        <div>
          <InputGroup>
            <div className="w-auto flex-1">
              <TextInput
                type="number"
                name="measurement"
                onChange={handleFormChange}
                value={storeProductForm.measurement}
                required
                label="Total batch measurement"
                data-testid="measurementInput"
              />
            </div>
            <div className="w-40">
              <Select
                id="unit"
                name="unit"
                value={storeProductForm.unit}
                required
                onChange={handleFormChange}
                data-testid="measurementUnit"
                className="bg-soft-grey"
                label="Unit">
                <option value="" disabled>
                  Select a unit
                </option>
                {measurementUnits.map((measurementUnit) => (
                  <option key={measurementUnit.name} value={measurementUnit.name}>{`${capitalize(
                    measurementUnit.category
                  )}: ${measurementUnit.symbol}`}</option>
                ))}
              </Select>
            </div>
          </InputGroup>
        </div>
        <div>
          <TextInput
            type="number"
            name="price"
            id="price"
            disabled
            value={storeProductForm.price}
            required={true}
            onChange={handleFormChange}
            label="Production cost"
            data-testid="costPrice"
            prepend={
              <span className="text-xl">
                <CurrencySymbol />
              </span>
            }
          />
        </div>
        <div>
          <p>
            Cost per unit:&nbsp;
            {storeProductForm.measurement && (
              <span>
                <Amount amount={+storeProductForm.price / +storeProductForm.measurement} />
                &nbsp; per &nbsp;
                {measurementUnits.find((unit) => unit.name == storeProductForm.unit)?.symbol}
              </span>
            )}
          </p>
        </div>
        <Button
          type="submit"
          data-testid="submitButton"
          disabled={!formIsValid}
          loading={loaders.savingStoreItem}
          className="w-full mt-6">
          Save
        </Button>
      </form>
    </div>
  );
};

export default CreateStoreItemProduct;
