import { PurchasedTemplates } from "components/PurchasedTemplates/PurchasedTemplates";
import { useTemplatePurchases } from "hooks/useTemplatePurchases/useTemplatePurchases";
import React from "react";

const PurchasedTemplatesPage = (): React.ReactElement => {
  const templateProps = useTemplatePurchases();
  return <PurchasedTemplates {...templateProps} />;
};

export default PurchasedTemplatesPage;
