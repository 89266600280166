import React, { SVGProps } from "react";

const ArrowRight = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="mask0_907_4603" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
        <rect width="30" height="30" transform="matrix(-1 0 0 1 30 0)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_907_4603)">
        <path
          d="M15 24.5312L24.5312 15L15 5.46875L13.6562 6.8125L20.9375 14.0625H5.46875V15.9375H20.9375L13.6562 23.1875L15 24.5312Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default ArrowRight;
