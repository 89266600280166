import React from "react";
import { useNavigate } from "react-router-dom";
import { DialogProps } from "types";
import Dialog from "../Dialog/Dialog";

export const CreateDialog = ({
  isOpen,
  setIsOpen,
  title,
  option1,
  option2
}: Omit<DialogProps, "children"> & {
  option1: { onClick?: () => void; link?: string; title: string; desc: string };
  option2: { onClick?: () => void; link?: string; title: string; desc: string };
}): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} titleClassName="text-center" title={title}>
      <div className="sm:px-10 flex-wrap sm:flex-nowrap flex gap-4 text-center pb-8">
        <a
          href="#"
          className="w-full flex-grow"
          onClick={() => {
            option1.onClick?.();
            option1.link && navigate(option1.link);
          }}>
          <div className="flex flex-col items-center justify-center px-4 bg-[#E6F6F5] gap-1 py-8">
            <svg
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="55" height="55" rx="27.5" fill="#00A89D" />
              <path
                d="M35.1923 35.5H19.8077C19.3026 35.5 18.875 35.325 18.525 34.975C18.175 34.625 18 34.1974 18 33.6923V22.3077C18 21.8026 18.175 21.375 18.525 21.025C18.875 20.675 19.3026 20.5 19.8077 20.5H29.327V22H19.8077C19.7307 22 19.6603 22.0321 19.5963 22.0962C19.5321 22.1602 19.5 22.2308 19.5 22.3077V33.6923C19.5 33.7692 19.5321 33.8398 19.5963 33.9038C19.6603 33.9679 19.7307 34 19.8077 34H35.1923C35.2693 34 35.3398 33.9679 35.4038 33.9038C35.4679 33.8398 35.5 33.7692 35.5 33.6923V27.077H37V33.6923C37 34.1974 36.825 34.625 36.475 34.975C36.125 35.325 35.6974 35.5 35.1923 35.5ZM21.8848 31.673H28.25V29.1923H21.8848V31.673ZM21.8848 26.8078H28.25V24.327H21.8848V26.8078ZM30.6348 31.673H33.1152V27.077H30.6348V31.673ZM33.5 25V23H31.5V21.5H33.5V19.5H35V21.5H37V23H35V25H33.5Z"
                fill="white"
              />
            </svg>
            <h5 className="font-semibold mt-2">{option1.title}</h5>
            <p className="text-grey-60 text-sm">{option1.desc} </p>
          </div>
        </a>
        <a
          href="#"
          className="w-full flex-grow"
          onClick={() => {
            option2.onClick?.();
            option2.link && navigate(option2.link);
          }}>
          <div className="flex flex-col items-center justify-center px-4 bg-[#F4F9EC] gap-1 py-8">
            <svg
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect width="55" height="55" rx="27.5" fill="#8DC740" />
              <mask
                id="mask0_3114_7345"
                maskUnits="userSpaceOnUse"
                x="15"
                y="15"
                width="25"
                height="25">
                <rect x="15.5" y="15.5" width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_3114_7345)">
                <path
                  d="M26.75 32.25H28.25V28.25H32.25V26.75H28.25V22.75H26.75V26.75H22.75V28.25H26.75V32.25ZM20.8077 36C20.3026 36 19.875 35.825 19.525 35.475C19.175 35.125 19 34.6974 19 34.1923V20.8077C19 20.3026 19.175 19.875 19.525 19.525C19.875 19.175 20.3026 19 20.8077 19H34.1923C34.6974 19 35.125 19.175 35.475 19.525C35.825 19.875 36 20.3026 36 20.8077V34.1923C36 34.6974 35.825 35.125 35.475 35.475C35.125 35.825 34.6974 36 34.1923 36H20.8077ZM20.8077 34.5H34.1923C34.2692 34.5 34.3398 34.4679 34.4038 34.4038C34.4679 34.3398 34.5 34.2692 34.5 34.1923V20.8077C34.5 20.7307 34.4679 20.6602 34.4038 20.5962C34.3398 20.5321 34.2692 20.5 34.1923 20.5H20.8077C20.7307 20.5 20.6603 20.5321 20.5963 20.5962C20.5321 20.6602 20.5 20.7307 20.5 20.8077V34.1923C20.5 34.2692 20.5321 34.3398 20.5963 34.4038C20.6603 34.4679 20.7307 34.5 20.8077 34.5Z"
                  fill="white"
                />
              </g>
            </svg>
            <h5 className="font-semibold mt-2">{option2.title}</h5>
            <p className="text-grey-60 text-sm">{option2.desc}</p>
          </div>
        </a>
      </div>
    </Dialog>
  );
};
