import { useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { AUTOSAVE_INTERVAL } from "constants/general";
import {
  ERROR_OCCURRED,
  PRODUCTION_ACTIVITY_ADDED,
  PRODUCTION_ACTIVITY_REMOVED,
  REQUEST_SUCCESSFUL
} from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import deepEqual from "deep-equal";
import { track } from "helpers/analytics";
import { updateProperty } from "helpers/object";
import useActivity from "hooks/useActivity";
import { useEffect, useMemo, useState } from "react";
import { useAutosave } from "react-autosave";
import { useParams } from "react-router-dom";
import {
  ActivityTimeForm,
  FormChangeEvent,
  ObjectChanges,
  ProductActivityLoaders,
  Response,
  TemplateVariant,
  TemplateVariantActivity
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

export const useTemplateActivity = (templateVariants: TemplateVariant[]) => {
  const { templateId } = useParams();
  const { showAlert } = useAlert();
  const { businessActivities, handleGetActivities } = useActivity();
  const queryClient = useQueryClient();

  // UseStates
  const [activityTimeForm, setActivityTimeForm] = useState<ActivityTimeForm>({
    time: "",
    unit: "HOUR"
  });
  const [templateVariantActivities, setTemplateVariantActivities] = useState<
    TemplateVariantActivity[]
  >([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loaders, setLoaders] = useState<ProductActivityLoaders>({
    savingActivities: false,
    fetchingActivities: true
  });
  const [error, setError] = useState("");
  const [variantsHaveChanges, setVariantsHaveChanges] = useState<ObjectChanges>({});
  const savedTemplateVariantActivities = useMemo<TemplateVariantActivity[]>(
    () =>
      templateVariants.map((variant) => ({
        variantId: variant.variantId,
        variantName: variant.name,
        activityTimes: variant.activityTimes
      })),
    [templateVariants]
  );
  useAutosave({
    data: templateVariantActivities,
    onSave: async (): Promise<void> => {
      if (hasUnsavedChanges) {
        setLoaders((prev) => ({ ...prev, savingActivities: true }));
        await handleSaveActivityTime();
        setLoaders((prev) => ({ ...prev, savingActivities: false }));
      }
    },
    interval: AUTOSAVE_INTERVAL,
    saveOnUnmount: true
  });

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setActivityTimeForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleVariantActivitiesChange = (
    event: FormChangeEvent,
    variantId: string,
    productionActivityId: string
  ): void => {
    const { name, value } = event.target;
    setTemplateVariantActivities((prev) => {
      return prev.map((variant) => {
        const activityTimes = variant.activityTimes.map((time) =>
          updateProperty(time.productionActivityId === productionActivityId, time, {
            [name]: value
          })
        );
        return updateProperty(variant.variantId === variantId, variant, { activityTimes });
      });
    });
    setError("");
  };

  const handleAddActivity = async (
    businessActivityId: string,
    activityName: string
  ): Promise<boolean> => {
    setError("");
    const requestData = {
      templateId,
      businessActivityId
    };

    try {
      const json: Response<string> = await api
        .post("product-template-activity", { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_PRODUCTION_ACTIVITY_ADDED, {
          templateId,
          activityName
        });
        showAlert(AlertType.SUCCESS, PRODUCTION_ACTIVITY_ADDED);
        await queryClient.invalidateQueries({ queryKey: ["template_configurations"] });
        await queryClient.invalidateQueries({ queryKey: ["template"] });
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleRemoveActivity = async (productionActivityId: string): Promise<boolean> => {
    setError("");
    try {
      const json: Response<void> = await api
        .delete(`product-template-activity/${productionActivityId}`)
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_PRODUCTION_ACTIVITY_REMOVED, {
          templateId,
          productionActivityId
        });
        showAlert(AlertType.SUCCESS, PRODUCTION_ACTIVITY_REMOVED);
        await queryClient.invalidateQueries({ queryKey: ["template_configurations"] });
        await queryClient.invalidateQueries({ queryKey: ["template"] });
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleSaveActivityTime = async (): Promise<boolean> => {
    const requestData = {
      variants: templateVariantActivities.map(({ variantId, activityTimes }) => ({
        variantId,
        activityTimes: activityTimes.map(({ productionActivityId, time, unit }) => ({
          time: +time,
          unit,
          productionActivityId
        }))
      }))
    };

    try {
      const json: Response<string> = await api
        .put(`product-template/${templateId}/activity-times`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        await queryClient.invalidateQueries({ queryKey: ["template_configurations"] });
        await queryClient.invalidateQueries({ queryKey: ["template"] });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    setLoaders((prev) => ({ ...prev, savingActivities: true }));
    const result = await handleSaveActivityTime();

    setLoaders((prev) => ({ ...prev, savingActivities: false }));
    if (result) {
      showAlert(AlertType.SUCCESS, REQUEST_SUCCESSFUL);
    } else {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
    }
  };

  // UseEffects
  useEffect(() => {
    setHasUnsavedChanges(!deepEqual(templateVariantActivities, savedTemplateVariantActivities));
    const variantsHaveChanges: ObjectChanges = {};
    templateVariantActivities.forEach((variant) => {
      variantsHaveChanges[variant.variantId] = !deepEqual(
        variant,
        savedTemplateVariantActivities.find(
          (savedVariant) => savedVariant.variantId == variant.variantId
        )
      );
    });
    setVariantsHaveChanges(variantsHaveChanges);
  }, [savedTemplateVariantActivities, templateVariantActivities]);

  useEffect(() => {
    handleGetActivities();
  }, []);

  useEffect(() => {
    // Get variant activities
    setTemplateVariantActivities((prev) => {
      return templateVariants.map((variant) => {
        const existingProductVariant = prev.find(
          (oldVariant) => oldVariant.variantId === variant.variantId
        );
        return updateProperty(
          !!existingProductVariant,
          {
            variantId: variant.variantId,
            variantName: variant.name,
            activityTimes: variant.activityTimes
          },
          {
            activityTimes: variant.activityTimes.map((time) => {
              const existingTime = existingProductVariant?.activityTimes.find(
                (oldTime) => oldTime.productionActivityId == time.productionActivityId
              );
              return !existingTime
                ? time
                : {
                    ...time,
                    time: +existingTime.time,
                    unit: existingTime.unit
                  };
            })
          }
        );
      });
    });
  }, [templateVariants]);

  return {
    activityTimeForm,
    businessActivities,
    handleFormSubmit,
    handleFormChange,
    error,
    hasUnsavedChanges,
    handleVariantActivitiesChange,
    templateId,
    handleAddActivity,
    handleRemoveActivity,
    loaders,
    templateVariantActivities,
    variantsHaveChanges
  };
};

export type UseTemplateActivityType = ReturnType<typeof useTemplateActivity>;
