import LoadingComponent from "components/Shared/Loading";
import { Shopfront } from "components/Shopfront/Shopfront";
import { useProductOfferings } from "hooks/useProductOfferings/useProductOfferings";
import React from "react";

export const ShopFrontPage = (): React.ReactElement => {
  const { loaders, productOfferings } = useProductOfferings();

  return (
    <LoadingComponent loading={loaders.fetchingOfferings}>
      <Shopfront productOfferings={productOfferings} />
    </LoadingComponent>
  );
};
