import React from "react";
import { DefaultComponentProps } from "types";
import cx from "classnames";

const SubTitle = (props: DefaultComponentProps): React.ReactElement => {
  const { children } = props;
  return (
    <p data-testid="subtitle" className={cx("text-center text-base md:text-lg", props.className)}>
      {children}
    </p>
  );
};

export default SubTitle;
