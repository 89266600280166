export enum Period {
  MONTH = "MONTH",
  THREE_MONTHS = "THREE_MONTHS",
  SIX_MONTHS = "SIX_MONTHS",
  NINE_MONTHS = "NINE_MONTHS",
  YEAR = "YEAR",
  TWO_YEARS = "TWO_YEARS",
  THREE_YEARS = "THREE_YEARS",
  FOUR_YEARS = "FOUR_YEARS",
  CUSTOM = "CUSTOM"
}

export function periodToLabel(period: Period): string {
  switch (period) {
    case Period.MONTH:
      return "1 month";
    case Period.THREE_MONTHS:
      return "3 months";
    case Period.SIX_MONTHS:
      return "6 months";
    case Period.NINE_MONTHS:
      return "9 months";
    case Period.YEAR:
      return "1 year";
    case Period.TWO_YEARS:
      return "2 years";
    case Period.THREE_YEARS:
      return "3 years";
    case Period.FOUR_YEARS:
      return "4 years";
    case Period.CUSTOM:
      return "Custom";
    default:
      return "Custom";
  }
}

export enum ExpenseCategory {
  OTHER = "OTHER"
}

export enum SegmentEvent {
  SIGNED_UP = "Signed Up",
  SIGNED_IN = "Signed In",
  BUSINESS_REGISTERED = "Business Registered",
  MEMBER_REGISTERED = "Member Registered",
  BUSINESS_ADDED = "Business Created",
  BUSINESS_MODIFIED = "Business Modified",
  EXPENSE_ADDED = "Expense Added",
  EXPENSE_MODIFIED = "Expense Modified",
  ORDER_ADDED = "Order Added",
  ORDER_PAYMENT_ADDED = "Order Payment Added",
  PRODUCT_ADDED = "Product Added",
  VARIANT_ADDED = "Variant Added",
  VARIANT_MODIFIED = "Variant Modified",
  REPORT_VIEWED = "Report Viewed",
  PAGE_VIEWED = "Page Viewed",
  STORE_ITEM_ADDED = "Store Item Added",
  STORE_ITEM_MODIFIED = "Store Item Modified",
  COST_PER_UNIT_MODIFIED = "Cost Per Unit Modified",
  AVAILABLE_STOCK_MODIFIED = "Available Stock Modified",
  PURCHASE_ADDED = "Purchase Added",
  COMPONENT_ATTACHED = "Component Attached",
  COMPONENT_DETACHED = "Component Detached",
  ADDON_ADDED = "AddOn Added",
  ADDON_REMOVED = "AddOns Removed",
  PRODUCTION_ACTIVITY_ADDED = "Production Activity Added",
  PRODUCTION_ACTIVITY_REMOVED = "Production Activity Removed",
  ACTIVITY_ADDED = "Activity Added",
  ACTIVITY_MODIFIED = "Activity Modified",
  ACTIVITY_REMOVED = "Activity Removed",
  MEMBER_INVITED = "Member Invited",
  MEMBER_REMOVED = "Member Removed",
  MEMBER_MODIFIED = "Member Modified",
  INVITE_ACCEPTED = "Invite Accepted",
  FEATURE_TOGGLED = "Feature Toggled",
  ORDER_TEMPLATE_ADDED = "Order Template Added",
  ORDER_TEMPLATE_MODIFIED = "Order Template Modified",
  BUSINESS_PAYMENT_METHOD_ADDED = "Business Payment Method Added",
  BUSINESS_PAYMENT_METHOD_MODIFIED = "Business Payment Method Modified",
  INVOICE_GENERATED = "Invoice Generated",
  INVOICE_SENT_VIA_WHATSAPP = "Invoice Sent Via WhatsApp",
  INVOICE_SENT_VIA_EMAIL = "Invoice Sent Via Email",
  INVOICE_VIEWED = "Invoice Viewed",
  TEMPLATE_ADDED = "Template Added",
  TEMPLATE_MODIFIED = "Template Modified",
  TEMPLATE_IMAGE_SAVED = "Template Image Saved",
  TEMPLATE_DESC_SAVED = "Template Description Saved",
  TEMPLATE_PUBLISHED = "Template Published",
  TEMPLATE_UNPUBLISHED = "Template Unpublished",
  TEMPLATE_VARIANT_ADDED = "Template Variant Added",
  TEMPLATE_VARIANT_MODIFIED = "Template Variant Modified",
  TEMPLATE_COMPONENT_ATTACHED = "Template Component Attached",
  TEMPLATE_COMPONENT_DETACHED = "Template Component Detached",
  TEMPLATE_ADDON_ADDED = "Template AddOn Added",
  TEMPLATE_ADDON_REMOVED = "Template AddOns Removed",
  TEMPLATE_PRODUCTION_ACTIVITY_ADDED = "Template Production Activity Added",
  TEMPLATE_PRODUCTION_ACTIVITY_REMOVED = "Template Production Activity Removed"
}

export enum FeatureFlag {
  STORE = "store_item_allocation",
  EXPENSES = "expenses",
  DASHBOARD = "analysis_dashboard",
  PRODUCTS = "products",
  ORDERS = "orders",
  SETTINGS = "settings",
  TEMPLATES = "templates",
  SHOP_FRONT = "shop_front"
}

export enum AlertType {
  DANGER = "danger",
  WARNING = "warning",
  SUCCESS = "success",
  INFO = "info"
}
