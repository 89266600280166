import { ArrowOut } from "icons/landing/arrow-out";
import { ArrowOutward } from "icons/landing/arrow-outward";
import { BarChart } from "icons/landing/bar-chart";
import { CreditCard } from "icons/landing/credit-card";
import "./landing.css";
import React, { useState } from "react";
import { SimpleAccordion } from "components/Shared/SimpleAccordion/SimpleAccordion";
import { Link } from "react-router-dom";
import Toggle from "components/Shared/Toggle";
import Select from "components/Shared/Select";
import { RangeInput } from "components/Shared/RangeInput/RangeInput";
import { FormChangeEvent } from "types";

const LandingPage = (): React.ReactElement => {
  const [annually, setAnnually] = useState(true);
  const [ngn, setNgn] = useState(true);
  const [seats, setSeats] = useState(2);
  const ngnMonthly = 6000,
    ngnAnual = 4000,
    usdMonthly = 9,
    usdAnnual = 6;
  return (
    <div>
      <div className="bl-hero w-screen flex flex-col items-center">
        <header className="flex justify-between bl-container py-8 w-full">
          <Link to="/" data-testid="homeLink">
            <img src="images/logo.svg" alt="Bloom Logo" className="w-32 sm:w-44" />
          </Link>
          <span className="">
            <a href="#pricing">
              <span className="link mr-4">Pricing</span>
            </a>
            <Link to="/signin">
              <span className="link mr-4">Login</span>
            </Link>
            <Link to="/signup">
              <button className="rounded-full text-center text-white hover:to-primary lg:inline-flex justify-center font-bold bg-gradient-to-r from-primary to-secondary px-6 py-3 hidden">
                Get early access
              </button>
            </Link>
          </span>
        </header>
        <div className="flex bl-container pt-16 pb-24 flex-col lg:flex-row">
          <div className="lg:w-1/2 flex space-y-8 flex-col justify-center pb-8 lg:pb-0 lg:pr-8 text-center lg:text-left">
            <h2 className="text-2xl lg:text-5xl font-extrabold">
              Simple & smart way to monitor the health of your business.
            </h2>
            <p data-testid="subtitle" className="text-base md:text-lg">
              Know when you break even, track expenses, track inventory, record sales, send invoices
              and more - all in one place.
            </p>
            <span className="flex flex-col lg:flex-row space-y-4">
              <Link to="/signup">
                <button className="rounded-full text-center text-white hover:to-primary inline-flex justify-center font-bold bg-gradient-to-r from-primary to-secondary px-6 py-3">
                  Get early access
                </button>
              </Link>
              {/* <span className="link ml-4">Watch 1 minute demo</span> */}
            </span>
          </div>
          <div className="lg:w-1/2 flex justify-center">
            <div className="relative flex justify-center items-center max-w-md">
              <img src="images/bloompulse-hero.gif" className="bl-hero-gif" alt="" />
              <img src="images/hero-chart2.svg" className="absolute left-0 right-0 w-full" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center rounded-3xl bg-white -mt-10 -mb-10 pb-20 relative">
        <div className="bl-section w-full !flex-col">
          <h4 className="font-bold text-2xl lg:text-3xl pb-4">See how Bloompulse works</h4>
          <div
            className="container !m-0"
            style={{ position: "relative", width: "100%", height: 0, paddingBottom: "56.25%" }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/jeafpkzlGKs?si=EGxmWfBb6zjx--uv"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}></iframe>
          </div>
        </div>
        <div className="bl-section">
          <img src="images/overview.svg" alt="" className="bl-section-img" />
          <div className="bl-section-desc">
            <BarChart />
            <h4 className="font-bold text-2xl lg:text-3xl">Track your profitability in a glance</h4>
            <p className="text-grey-50">
              Get a concise and visual summary of your business health, enabling you to quickly
              grasp the most important insights at a glance.
            </p>
          </div>
        </div>
        <div className="bl-section">
          <div className="bl-section-desc">
            <CreditCard />
            <h4 className="font-bold text-2xl lg:text-3xl">
              Sell products at the right profit margin
            </h4>
            <p className="text-grey-50">
              Properly account for all your production cost, expenses and other costs and Bloompulse
              will generate the cost price and the profit on each sale.
            </p>
          </div>
          <img src="images/price2.svg" alt="" className="bl-section-img" />
        </div>
        <div className="bl-section">
          <img src="images/expenses.svg" alt="" className="bl-section-img" />
          <div className="bl-section-desc">
            <ArrowOutward />
            <h4 className="font-bold text-2xl lg:text-3xl">Stay on top of expenses</h4>
            <p className="text-grey-50">
              Record and monitor all financial transactions to gain a clear understanding of where
              money is being spent and to manage budgeting effectively.
            </p>
          </div>
        </div>
        <div className="bl-section">
          <div className="bl-section-desc">
            <CreditCard />
            <h4 className="font-bold text-2xl lg:text-3xl">
              Record orders and send invoice to customers
            </h4>
            <p className="text-grey-50">
              Document the details of your orders and send invoice to your customers while
              Bloompulse automatically tracks your revenues.
            </p>
          </div>
          <img src="images/orders2.svg" alt="" className="bl-section-img" />
        </div>
        <div className="bl-section !border-b-0">
          <img src="images/users.svg" alt="" className="bl-section-img" />
          <div className="bl-section-desc">
            <ArrowOutward />
            <h4 className="font-bold text-2xl lg:text-3xl">Get your team involved</h4>
            <p className="text-grey-50">
              Invite your team members while keeping things private with fine-grained access
              controls. The notifications tab allows you see what everyone’s been up to.
            </p>
          </div>
        </div>
      </div>
      <div
        className="bg-white w-screen flex flex-col items-center pb-10 rounded-3xl z-10 -mb-10 relative"
        id="pricing">
        <div className="flex flex-col items-center w-4/5 lg:w-1/2 pt-8 sm:pt-16 pb-16 sm:pb-24 p-8">
          <h4 data-testid="title" className="text-4xl font-extrabold text-center mb-4">
            Pricing
          </h4>
          <span className="text-sm font-medium  text-grey-50 mr-3 text-center mb-2">
            Get 2 months free with the annual plan
          </span>
          <div className="flex flex-col sm:flex-row justify-between items-center w-full">
            <div className="flex items-center">
              <Select
                inputSize="sm"
                id="discountType"
                name="discountType"
                value={ngn ? "ngn" : "usd"}
                required
                onChange={() => setNgn((prev) => !prev)}
                label="">
                <option value="" disabled>
                  Select a currency
                </option>
                <option value="ngn">₦ Naira</option> <option value="usd">$ USD</option>
              </Select>
            </div>
            <div className="flex items-center">
              <span className="text-sm font-medium text-dark-grey mr-3">Monthly</span>
              <Toggle
                id="annual"
                name="annual"
                data-testid="annual"
                label="Annual"
                checked={annually}
                onChange={() => setAnnually((prev) => !prev)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 lg:space-x-8 bl-container w-full !border-0">
          <div className="p-4 lg:p-6 border border-primary rounded-tl-3xl rounded-br-3xl w-full sm:w-1/2 lg:w-2/5 flex flex-col">
            <div className="flex justify-between">
              <h4 data-testid="title" className="text-2xl font-normal text-primary">
                Free
              </h4>
              <div className="flex items-start">
                <span>{ngn ? "₦" : "$"}</span>
                <h4 data-testid="title" className="text-5xl font-normal">
                  0
                </h4>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col text-sm">
                <span>per business</span>
                <span>no member</span> <span>Up to 30 orders</span>
              </div>
              <div className="text-sm text-grey-50">
                <span>until you start making profit</span>
              </div>
            </div>
            <div className="text-center flex-1 flex flex-col justify-between items-center">
              <div></div>
              <Link to="/signup">
                <button className="rounded-full text-center text-white inline-flex justify-center font-bold bg-primary px-6 py-3 mt-4">
                  Get started
                </button>
              </Link>
            </div>
          </div>
          <div className="p-4 lg:p-6 border border-primary rounded-tl-3xl rounded-br-3xl w-full sm:w-1/2 lg:w-2/5">
            <div className="flex justify-between">
              <h4 data-testid="title" className="text-2xl font-normal text-primary">
                Pro
              </h4>
              <div className="flex items-start">
                <span>{ngn ? "₦" : "$"}</span>
                <h4 data-testid="title" className="text-5xl font-normal">
                  {ngn
                    ? !annually
                      ? ngnMonthly * (seats - 1)
                      : ngnAnual * (seats - 1)
                    : !annually
                    ? usdMonthly * (seats - 1)
                    : usdAnnual * (seats - 1)}
                </h4>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col text-sm">
                <span>per business</span>
                <span>{seats} members</span>
                <span>Unlimited orders</span>
              </div>
              <div className="text-sm text-grey-50">
                <span> {!annually ? "per month billed monthly" : "per month billed yearly"}</span>
              </div>
            </div>
            <div className="js my-2">
              <div className="flex justify-between">
                <span className="text-sm">0</span>
                <span className="text-sm">20</span>
              </div>
              <RangeInput
                className="w-full"
                onChange={(event: FormChangeEvent): void => {
                  setSeats(+event.target.value >= 2 ? +event.target.value : 2);
                }}
                value={seats}
                min={0}
                max={20}
              />
              <span className="text-sm block text-center text-grey-50">
                2 free + {seats - 2} additional members
              </span>
            </div>
            <div className="text-center">
              <Link to="/signup">
                {" "}
                <button className="rounded-full text-center text-white inline-flex justify-center font-bold bg-primary px-6 py-3 mt-4">
                  Get started {annually && "and save 33%"}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bl-hero-bt w-screen flex flex-col items-center pt-16 pb-10 z-20">
        <div className="flex flex-col items-center lg:w-1/2 pt-16 pb-24 space-y-8 p-8">
          <h4 data-testid="title" className="text-2xl font-extrabold text-center">
            See what Bloompulse can do for your business.
          </h4>
          <Link to="/signup">
            <button className="rounded-full text-center text-white hover:to-primary inline-flex justify-center font-bold bg-gradient-to-r from-primary to-secondary px-6 py-3">
              Get early access <ArrowOut />
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-dark-grey text-white rounded-t-3xl py-4 lg:p-20 -mt-10 flex flex-col items-center">
        <div className="bl-container flex flex-col items-center">
          <h2 className="text-2xl lg:text-5xl font-extrabold">Frequently asked questions</h2>
          <div className="pt-10">
            <SimpleAccordion title="How and when can I get access?">
              Once you fill out the early access request, we will get back to you as soon as
              possible. We typically respond within 24 hours.
            </SimpleAccordion>
            <SimpleAccordion title="Why is there a waitlist?">
              Beginning with a smaller user base enables us to provide personalized support, quick
              responses, and an intuitive user experience. This helps us gather valuable feedback
              and make necessary improvements based on real experiences, ensuring the best possible
              product for our users.
            </SimpleAccordion>
            <SimpleAccordion title="Does it cost money?">
              Our goal is to support the growth of your business responsibly and profitably. There
              is a monthly subscription fee of {ngn ? `₦${ngnMonthly}` : `$${usdMonthly}`}, that
              only applies when your business reaches a profitable month or records 30 order units,
              whichever comes first.
              <br />
              This fee covers your business with 2 free members. Additional members will incur a
              cost of {ngn ? `₦${ngnMonthly}` : `$${usdMonthly}`} per month per member.
            </SimpleAccordion>
            <SimpleAccordion title="Is my data secure when I use Bloompulse?">
              Your data&apos;s confidentiality and integrity are our top priority, and we
              continuously update our security measures to ensure it remains safe.
            </SimpleAccordion>
          </div>
        </div>
        <p className="text-grey-30 text-center pt-10">© 2024 Bloompulse. All rights reserved.</p>
      </div>
    </div>
  );
};

export default LandingPage;
