import TextInput from "../TextInput";
import React, { useEffect, useRef, useState } from "react";
import { AutocompleteProps } from "types";
import cx from "classnames";
import Check from "icons/Check";
import { clean } from "helpers/string";
import { useComponentVisible } from "hooks/shared/useComponentVisible/useComponentVisible";

const Autocomplete = (props: AutocompleteProps): React.ReactElement => {
  const {
    data,
    onDataSelected,
    dataIdName,
    disableNew,
    filter = true,
    defaultData,
    disableInput,
    ...textInputProps
  } = props;
  const [selectedData, setSelectedData] = useState(
    data.find((item) => clean(item.name) === clean(props.value as string))?.id
  );
  const [query, setQuery] = useState(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const { ref, isComponentVisible } = useComponentVisible<HTMLDivElement>(false);

  const filteredData =
    query === "" || !filter || selectedData
      ? data
      : data.filter((item) => clean(item.name).includes(clean(query as string)));

  const triggerChangeEvent = (target: HTMLInputElement, name: string, value: string): void => {
    props.onChange?.({
      ...new Event("change", { bubbles: true }),
      target: {
        ...target,
        value,
        name
      },
      nativeEvent: new Event("change", { bubbles: true }),
      isDefaultPrevented: (): boolean => {
        return true;
      },
      isPropagationStopped: (): boolean => {
        return true;
      },
      persist: () => {
        /* */
      },
      currentTarget: target
    });
  };

  useEffect(() => {
    // Query has changed so make a call to onChange handler to update value
    if (inputRef.current) {
      triggerChangeEvent(inputRef.current, props.name, query as string);
    }
    // Find matching item in the autocomplete list
    const item = data.find((item) => clean(item.name) === clean(query as string));
    setSelectedData(item?.id || "");
    onDataSelected?.(item);
    if (inputRef.current && dataIdName) {
      triggerChangeEvent(inputRef.current, dataIdName, item?.id || "");
    }
  }, [query]);

  useEffect(() => {
    if (disableNew && !isComponentVisible && !selectedData) {
      setQuery("");
    }
  }, [isComponentVisible]);

  return (
    <div className="relative">
      <div ref={ref}>
        <TextInput
          placeholder="Start typing to filter existing customers or add a new customer"
          {...textInputProps}
          onChange={(e): void => {
            if (!disableInput) {
              setQuery(e.target.value);
            }
          }}
          value={query}
          ref={inputRef}
          className={cx(disableInput && "caret-transparent")}
        />
      </div>
      <ul
        data-testid="dataList"
        className={cx(
          "hidden absolute left-0 right-0 top-full z-10 bg-white -mt-2 rounded-b-lg border-2 max-h-60 overflow-y-auto",
          isComponentVisible && "!block"
        )}>
        {filteredData.map((item) => (
          <li
            data-testid="dataItem"
            className={cx(
              "py-2 px-4 hover:bg-primary hover:text-white cursor-pointer flex justify-between"
            )}
            key={item.id}
            onClick={(): void => {
              setQuery(item.name);
            }}>
            {item.name}
            {selectedData === item.id && <Check />}
          </li>
        ))}
        {defaultData && <li className="py-2 px-4 bg-soft-grey">{defaultData}</li>}
      </ul>
    </div>
  );
};

export default Autocomplete;
