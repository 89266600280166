import { DefaultComponentProps } from "types";
import React from "react";
import cx from "classnames";
import Caret from "icons/Caret";

const Accordion = (
  props: DefaultComponentProps & {
    header?: React.ReactNode;
    itemId: string;
    activeKey: string;
    setActiveKey: React.Dispatch<React.SetStateAction<string>>;
    headerClassName?: string;
    contentClassName?: string;
  }
): React.ReactElement => {
  const {
    children,
    itemId,
    activeKey,
    setActiveKey,
    className,
    header,
    headerClassName,
    contentClassName,
    ...defaultProps
  } = props;
  const open = itemId == activeKey;
  return (
    <div
      {...defaultProps}
      data-testid="accordion"
      className={cx("rounded-lg my-2 text-dark-grey w-full", className)}>
      <div
        className={cx(
          "rounded-lg p-4 py-6 text-white bg-primary flex justify-between items-center font-bold cursor-pointer",
          open && "rounded-b-none border-x border-grey-30",
          headerClassName
        )}
        onClick={(): void => {
          setActiveKey(open ? "" : itemId);
        }}>
        {header}
        <Caret open={open} />
      </div>
      <div
        className={cx(
          "flex-grow overflow-hidden border-x border-b border-grey-30 rounded-b-lg",
          !open && "h-0 !border-0",
          contentClassName
        )}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
