import React from "react";
import { InstallProductTemplateProps } from "types";
import { ReviewTemplate } from "./ReviewTemplate/ReviewTemplate";
import { ProductSettings } from "./ProductSettings/ProductSettings";

export const InstallProductTemplate = (props: InstallProductTemplateProps): React.ReactElement => {
  const { activeTemplate } = props;
  return (
    <div>{activeTemplate ? <ProductSettings {...props} /> : <ReviewTemplate {...props} />}</div>
  );
};
