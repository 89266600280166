import { TemplateCard } from "components/PublishedTemplates/TemplateCard/TemplateCard";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import Title from "components/Shared/Title/Title";
import React from "react";
import { Link } from "react-router-dom";
import { PurchasedTemplatesProps } from "types";

export const PurchasedTemplates = ({
  templateSummary
}: PurchasedTemplatesProps): React.ReactElement => {
  return (
    <div className="">
      <Title className="pt-2 pb-6 px-4 md:px-0">
        Your Purchased Templates
        <InfoTooltip>These are the templates you have purchased</InfoTooltip>
      </Title>
      <div className="bg-white md:rounded-lg p-4 lg:p-6 mt-2 sm:mt-7">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-4">
          {templateSummary?.templates.map((template, index) => (
            <Link key={index} to={`/templates/library/${template.id}`} className="cursor-pointer">
              <TemplateCard template={template} />
            </Link>
          ))}
          {!templateSummary ||
            (templateSummary?.templates.length === 0 && <p>No templates found</p>)}
        </div>
      </div>
    </div>
  );
};
