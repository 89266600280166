import { TemplateCard } from "components/PublishedTemplates/TemplateCard/TemplateCard";
import { mockTemplates } from "components/PublishedTemplates/templateData";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import Loading from "components/Shared/Loading";
import { SearchInput } from "components/Shared/SearchInput/SearchInput";
import Select from "components/Shared/Select";
import TextInput from "components/Shared/TextInput";
import Title from "components/Shared/Title/Title";
import Search from "icons/Search";
import React from "react";
import { Link } from "react-router-dom";
import { TemplateLibraryCategoryProps } from "types";

export const TemplateLibrary = (props: TemplateLibraryCategoryProps): React.ReactElement => {
  const {
    businessTypeCategories,
    businessTypes,
    selectedBusinessType,
    setSelectedBusinessType,
    selectedCategory,
    setSelectedCategory,
    search,
    loaders,
    productTemplates
  } = props;
  return (
    <div>
      <Title className="pt-2 pb-6 px-4 md:px-0">
        Product Templates
        <InfoTooltip>These are the templates available for you.</InfoTooltip>
      </Title>
      <div className="flex flex-col-reverse sm:flex-row justify-between items-start sm:items-center px-4 md:px-0">
        <div className="flex md:gap-4 items-center sm:flex-nowrap flex-wrap gap-x-2 gap-y-0">
          <div className="w-[48%] sm:w-40">
            <Select
              simple
              inputSize="sm"
              name="businessType"
              label=""
              defaultValue=""
              value={selectedBusinessType}
              onChange={(e) => {
                setSelectedBusinessType(e.target.value);
                setSelectedCategory(null);
              }}>
              <option value="">All business types</option>
              {businessTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-[48%] sm:w-32">
            <Select
              simple
              inputSize="sm"
              name="category"
              label=""
              defaultValue=""
              value={selectedCategory || ""}
              onChange={(e) => setSelectedCategory(e.target.value)}>
              <option value="">All categories</option>
              {businessTypeCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-[49%] sm:w-32">
            <Select simple inputSize="sm" name="price" label="" defaultValue="">
              <option value="">Price</option>
              <option value="1">N1 - N100</option>
              <option value="2">N101 - N200</option>
              <option value="3">{">N200"}</option>
            </Select>
          </div>
          <span
            className="link mb-2"
            onClick={() => {
              setSelectedCategory(null);
              search.setSearch("");
            }}>
            Clear filters
          </span>
        </div>

        <div className="w-full sm:w-60">
          <span>
            <SearchInput {...search} />
          </span>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 bg-white lg:p-8 p-4 rounded-lg  mt-2">
        <Loading loading={loaders.fetchingTemplates}>
          {productTemplates.map((template, index) => (
            <Link key={index} to={`/templates/library/${template.id}`} className="cursor-pointer">
              <TemplateCard template={template} library />
            </Link>
          ))}
          {productTemplates.length === 0 && <p>No templates found</p>}
        </Loading>
      </div>
    </div>
  );
};
