import React from "react";

const Delete = (): React.ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 -960 960 960"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h478q12.444 0 23.722 5T701-822l121 121q8 8 13 19.278 5 11.278 5 23.722v478q0 24-18 42t-42 18H180Zm600-536L656-780H180v600h600v-476ZM479.765-245Q523-245 553.5-275.265q30.5-30.264 30.5-73.5Q584-392 553.735-422.5q-30.264-30.5-73.5-30.5Q437-453 406.5-422.735q-30.5 30.264-30.5 73.5Q376-306 406.265-275.5q30.264 30.5 73.5 30.5ZM263-584h298q12.75 0 21.375-8.625T591-614v-83q0-12.75-8.625-21.375T561-727H263q-12.75 0-21.375 8.625T233-697v83q0 12.75 8.625 21.375T263-584Zm-83-72v476-600 124Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Delete;
