import { SelectProps } from "types";
import React from "react";
import "../TextInput/TextInput.css";
import cx from "classnames";

const Select = (props: SelectProps): React.ReactElement => {
  const { label, inputSize, simple = false, ...selectProps } = props;
  return (
    <div
      className={cx(
        "input-main",
        inputSize || "md",
        selectProps.disabled && "disabled",
        !label && "no-label"
      )}
      data-testid="selectContainer">
      <div className="input-box">
        <select
          data-testid="selectInput"
          id={props.name}
          {...selectProps}
          className={`input ${selectProps.className}`}></select>
        <label htmlFor={props.name} className="label" data-testid="selectLabel">
          {label}
          {selectProps.required && <span className="text-red-500"> *</span>}
        </label>
        {!simple && <div className="input-underline" />}
      </div>
    </div>
  );
};

export default Select;
