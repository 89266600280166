import CreateEditOrder from "components/CreateEditOrder";
import LoadingComponent from "components/Shared/Loading";
import useCustomer from "hooks/useCustomer";
import useOrderForm from "hooks/useOrderForm";
import { useProductsWithVariants } from "hooks/useProduct/useProductsWithVariants";
import { useProductOfferings } from "hooks/useProductOfferings/useProductOfferings";
import React from "react";

const CreateOrderPage = (): React.ReactElement => {
  const props = useOrderForm();
  const { productsWithVariants, loaders } = useProductsWithVariants();
  const { customers } = useCustomer();
  const { productOfferings } = useProductOfferings();

  return (
    <LoadingComponent loading={loaders.fetchingProduct}>
      <CreateEditOrder
        {...props}
        productOfferings={productOfferings}
        products={productsWithVariants}
        customers={customers}
      />
    </LoadingComponent>
  );
};

export default CreateOrderPage;
