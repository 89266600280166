import OrderProductionComponent from "components/OrderProduction";
import LoadingComponent from "components/Shared/Loading";
import useActivity from "hooks/useActivity";
import { useAddOns } from "hooks/useAddOns/useAddOns";
import useOrderProduction from "hooks/useOrderProduction";
import useStoreItems from "hooks/useStoreItems";
import React, { useEffect } from "react";

const OrderProductionCostPage = (): React.ReactElement => {
  const props = useOrderProduction();
  const { measurementUnits, storeItems } = useStoreItems();
  const { businessAddOns } = useAddOns();
  const { businessActivities, handleGetActivities } = useActivity();

  useEffect(() => {
    handleGetActivities();
  }, []);

  return (
    <LoadingComponent loading={props.loaders.fetchingProductionDetails && !props.productionDetail}>
      {props.productionDetail && (
        <OrderProductionComponent
          {...props}
          productionDetail={props.productionDetail}
          measurementUnits={measurementUnits}
          storeItems={storeItems}
          businessAddOns={businessAddOns}
          businessActivities={businessActivities}
        />
      )}
    </LoadingComponent>
  );
};

export default OrderProductionCostPage;
