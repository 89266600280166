import { InstallProductTemplate } from "components/InstallProductTemplate/InstallProductTemplate";
import LoadingComponent from "components/Shared/Loading";
import { useTemplateInstall } from "hooks/useTemplateInstall/useTemplateInstall";
import React from "react";

export const InstallProductTemplatePage = (): React.ReactElement => {
  const props = useTemplateInstall();

  return (
    <LoadingComponent loading={props.loaders.fetchingProduct || props.loaders.fetchingTemplate}>
      <InstallProductTemplate {...props} />
    </LoadingComponent>
  );
};
