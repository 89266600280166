import LoadingComponent from "components/Shared/Loading";
import StoreComponent from "components/Store";
import useStoreItems from "hooks/useStoreItems";
import React from "react";

const StorePage = (): React.ReactElement => {
  const props = useStoreItems();

  return (
    <LoadingComponent loading={props.loaders.fetchingStoreItems && !props.storeItems}>
      <StoreComponent {...props} />
    </LoadingComponent>
  );
};

export default StorePage;
