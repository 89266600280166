import React from "react";

const ArrowLeft = (): React.ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_159_1015" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_159_1015)">
        <path
          d="M12 19.625L4.375 12L12 4.375L13.075 5.45L7.25 11.25H19.625V12.75H7.25L13.075 18.55L12 19.625Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default ArrowLeft;
