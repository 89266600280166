import { useQuery } from "@tanstack/react-query";
import api from "config/api";
import { FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { AlertType } from "types/enum";
import { ProductOffering, Response } from "types";

export const useProductOfferings = () => {
  const { showAlert } = useAlert();

  const productOfferingsQuery = useQuery({
    queryKey: ["product-offerings"],
    queryFn: async (): Promise<ProductOffering[]> => {
      try {
        const json: Response<ProductOffering[]> = await api.get("product-offering").json();
        if (json.code === 200) {
          return json.data;
        }
      } catch (err) {
        showAlert(AlertType.DANGER, FETCH_FAILED);
        console.error(err);
      }
      return [];
    }
  });

  return {
    loaders: {
      fetchingOfferings: productOfferingsQuery.isFetching
    },
    productOfferings: productOfferingsQuery.data || []
  };
};

export type UseProductOfferingsType = ReturnType<typeof useProductOfferings>;
