import Button from "components/Shared/Button";
import Dialog from "components/Shared/Dialog";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import { UseAddOnsType } from "hooks/useAddOns/useAddOns";
import React from "react";
import { BasicHookType } from "types";

export type AddOnFormProps = UseAddOnsType & {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleFormSubmit: BasicHookType["handleFormSubmit"];
};

export const AddOnForm = (props: AddOnFormProps): React.ReactElement => {
  const { isOpen, setIsOpen, error, handleFormChange, addOnForm, loaders, handleFormSubmit } =
    props;
  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} title="Create Add-on">
      <form
        onSubmit={(e) => {
          handleFormSubmit(e);
          setIsOpen(false);
        }}
        className=""
        data-testid="addOnNameForm">
        <Error error={error} />
        <div>
          <TextInput
            type="text"
            name="name"
            onChange={handleFormChange}
            value={addOnForm.name}
            required
            label="Add-on name"
            data-testid="addOnName"
          />
        </div>
        <Button
          type="submit"
          data-testid="addOnSubmitButton"
          disabled={!addOnForm.name}
          loading={loaders.savingAddOns}
          className="w-full mt-6">
          Save
        </Button>
      </form>
    </Dialog>
  );
};
