import { CreateEditProductOffering } from "components/CreateEditProductOffering/CreateEditProductOffering";
import LoadingComponent from "components/Shared/Loading";
import { useProductsWithVariants } from "hooks/useProduct/useProductsWithVariants";
import { useProductOffering } from "hooks/useProductOffering/useProductOffering";
import React from "react";

export const CreateProductOfferingPage = (): React.ReactElement => {
  const props = useProductOffering();
  const { productsWithVariants, loaders } = useProductsWithVariants();

  return (
    <LoadingComponent loading={props.loaders.fetchingOffering || loaders.fetchingProduct}>
      <CreateEditProductOffering {...props} products={productsWithVariants} />
    </LoadingComponent>
  );
};
