import Button from "components/Shared/Button";
import IconButton from "components/Shared/IconButton";
import SubTitle from "components/Shared/SubTitle";
import Title from "components/Shared/Title";
import ArrowRight from "icons/ArrowRight";
import CheckCircle from "icons/CheckCircle";
import React from "react";
import { Link } from "react-router-dom";
import { ReviewTemplateProps } from "types";

export const ReviewTemplate = ({
  template,
  completedReviews,
  templateId,
  setActiveTemplate,
  installation,
  handleOnboardProduct,
  setActiveStep,
  loaders
}: ReviewTemplateProps): React.ReactElement => {
  const linkedTemplates =
    template?.linkedTemplates.filter((linkedTemplate) =>
      installation.linkedTemplates.find(
        (installationLinkedTemplate) => installationLinkedTemplate.templateId == linkedTemplate.id
      )
    ) || [];

  const mainProductIsReviwed = completedReviews[installation.templateId];
  // const noOfProducts = linkedTemplates.length + 1;
  // const noOfReviewedProducts = Object.keys(completedReviews).length;
  return (
    <div className="bg-white rounded-lg p-4 lg:p-10 w-full">
      <div className="text-center mb-8">
        <Title className="text-center">Install template</Title>
        <SubTitle>
          Review the configuration of the products to be added from this template.
        </SubTitle>
      </div>
      <SubTitle className="text-grey-50 !text-left">The main product</SubTitle>
      <div className="flex justify-between items-center bg-soft-grey rounded-lg px-6 py-2 my-4">
        <p className="font-bold text-xl" data-testid="productsName">
          {template?.name}
        </p>
        <IconButton
          iconColor={mainProductIsReviwed ? "primary" : "white"}
          iconEnd={!mainProductIsReviwed ? <ArrowRight /> : undefined}
          icon={mainProductIsReviwed ? <CheckCircle /> : undefined}
          onClick={() => {
            setActiveStep("Review components");
            setActiveTemplate(template);
          }}>
          Review configuration
        </IconButton>
      </div>
      {linkedTemplates.length > 0 && (
        <>
          <SubTitle className="text-grey-50 !text-left mt-6">
            Linked products that will also be created
          </SubTitle>
          {linkedTemplates.map((linkedTemplate) => (
            <div
              key={linkedTemplate.id}
              className="flex justify-between items-center bg-soft-grey rounded-lg px-6 py-2 my-4">
              <p className="font-bold text-xl" data-testid="productsName">
                {linkedTemplate?.name}
              </p>
              <IconButton
                // disabled={!mainProductIsReviwed}
                iconColor={completedReviews[linkedTemplate.id] ? "primary" : "white"}
                iconEnd={!completedReviews[linkedTemplate.id] ? <ArrowRight /> : undefined}
                icon={completedReviews[linkedTemplate.id] ? <CheckCircle /> : undefined}
                onClick={() => {
                  setActiveStep("Review components");
                  setActiveTemplate(linkedTemplate);
                }}>
                Review configuration
              </IconButton>
            </div>
          ))}
        </>
      )}
      <div className="my-6 flex flex-col items-center">
        <Button
          loading={loaders.onboardingProduct}
          onClick={handleOnboardProduct}
          // disabled={noOfProducts != noOfReviewedProducts}
        >
          Create products
        </Button>
        <Link to={`/templates/library/${templateId}/purchase`} data-testid="" className="mt-4 link">
          Cancel setup
        </Link>
      </div>
    </div>
  );
};
