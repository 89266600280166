import { useQueryClient } from "@tanstack/react-query";
import api from "config/api";
import { AUTOSAVE_INTERVAL } from "constants/general";
import { ADDON_ADDED, ADDON_REMOVED, ERROR_OCCURRED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import deepEqual from "deep-equal";
import { track } from "helpers/analytics";
import { useAddOns } from "hooks/useAddOns/useAddOns";
import { useEffect, useMemo, useState } from "react";
import { useAutosave } from "react-autosave";
import { useParams } from "react-router-dom";
import { Response, TemplateVariant, TemplateVariantAddOn } from "types";
import { AlertType, SegmentEvent } from "types/enum";

export const useTemplateAddOn = (templateVariants: TemplateVariant[]) => {
  const { templateId } = useParams();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const addOnProps = useAddOns(templateId);

  // UseStates
  const [templateVariantAddOns, setTemplateVariantAddOns] = useState<TemplateVariantAddOn[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [error, setError] = useState("");
  const savedTemplateVariantAddOns = useMemo<TemplateVariantAddOn[]>(
    () =>
      templateVariants.map((variant) => ({
        variantId: variant.variantId,
        variantName: variant.name,
        addOnCosts: variant.addOnCosts
      })),
    [templateVariants]
  );

  useAutosave({
    data: templateVariantAddOns,
    onSave: async (): Promise<void> => {
      if (hasUnsavedChanges) {
        //
      }
    },
    interval: AUTOSAVE_INTERVAL,
    saveOnUnmount: true
  });

  const handleAddAddOn = async (businessAddOnId: string): Promise<boolean> => {
    setError("");
    const requestData = {
      templateId,
      businessAddOnId
    };

    try {
      const json: Response<string> = await api
        .post("product-template-addon", { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_ADDON_ADDED, {
          templateId,
          businessAddOnId
        });
        showAlert(AlertType.SUCCESS, ADDON_ADDED);
        await queryClient.invalidateQueries({ queryKey: ["template_configurations"] });
        await queryClient.invalidateQueries({ queryKey: ["template"] });
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  const handleRemoveAddOn = async (addOnId: string): Promise<boolean> => {
    setError("");
    try {
      const json: Response<void> = await api.delete(`product-template-addon/${addOnId}`).json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_ADDON_REMOVED, {
          templateId,
          addOnId
        });
        showAlert(AlertType.SUCCESS, ADDON_REMOVED);
        await queryClient.invalidateQueries({ queryKey: ["template_configurations"] });
        await queryClient.invalidateQueries({ queryKey: ["template"] });
      } else {
        showAlert(AlertType.DANGER, ERROR_OCCURRED);
      }
      return isSuccessfull;
    } catch {
      showAlert(AlertType.DANGER, ERROR_OCCURRED);
      return false;
    }
  };

  // UseEffects
  useEffect(() => {
    setHasUnsavedChanges(!deepEqual(templateVariantAddOns, savedTemplateVariantAddOns));
  }, [savedTemplateVariantAddOns, templateVariantAddOns]);

  useEffect(() => {
    // Get variant formulas
    setTemplateVariantAddOns(() => {
      return templateVariants.map((variant) => ({
        variantId: variant.variantId,
        variantName: variant.name,
        addOnCosts: variant.addOnCosts
      }));
    });
  }, [templateVariants]);

  return {
    hasUnsavedChanges,
    templateId,
    handleAddAddOn,
    handleRemoveAddOn,
    templateVariantAddOns,
    addOnProps,
    loaders: addOnProps.loaders,
    error
  };
};

export type UseTemplateAddOnType = ReturnType<typeof useTemplateAddOn>;
