import { replaceAtIndex } from "helpers/array";
import useActivity from "hooks/useActivity";
import { useAddOns } from "hooks/useAddOns/useAddOns";
import useStoreItems from "hooks/useStoreItems";
import { useTemplate } from "hooks/useTemplate/useTemplate";
import { useEffect, useState } from "react";
import {
  AutocompleteData,
  LinkedTemplateComponentArgs,
  OnboardTemplateActivitiesArgs,
  OnboardTemplateAddOnsArgs,
  OnboardTemplateArgs,
  Template,
  TemplateConfigItemType
} from "types";
import {
  formatActivitiesToInstallationArgs,
  formatAddonsToInstallationArgs,
  formatComponentsToInstallationArgs,
  formatComponentsToLinkedInstallationArgs,
  removeReplacedActivity,
  removeReplacedAddom,
  removeReplacedStoreItem,
  replaceActivityAtIndex,
  replaceAddonAtIndex,
  replaceComponentAtIndex
} from "./useTemplateInstall.utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Response } from "types";
import api from "config/api";
import { extractErrorMessage } from "helpers/api";
import { AlertType } from "types/enum";
import { useAlert } from "context/alert/AlertContext";
import { PRODUCT_OBOARDED_SUCCESSFULLY } from "constants/response";
import { useNavigate } from "react-router-dom";

export const useTemplateInstall = () => {
  const { template, loaders: templateLoaders, templateId } = useTemplate();
  const [activeTemplate, setActiveTemplate] = useState<Template>();
  const [completedReviews, setCompletedReviews] = useState<Record<string, boolean>>({});
  const [activeStep, setActiveStep] = useState("Review components");
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const { storeItems } = useStoreItems();
  const { businessActivities, handleGetActivities } = useActivity();
  const { businessAddOns } = useAddOns();

  const [installation, setInstallation] = useState<OnboardTemplateArgs>({
    components: [],
    activities: [],
    addOns: [],
    linkedTemplates: [],
    templateId: ""
  });

  const handleInstallationComponentChange = (
    data: AutocompleteData,
    name: keyof LinkedTemplateComponentArgs,
    componentIndex: number,
    templateId: string
  ) => {
    if (templateId == installation.templateId) {
      const oldComponent = installation.components[componentIndex];

      const newInstallations = replaceComponentAtIndex(installation, componentIndex, data, name);
      setInstallation(newInstallations);

      const newComponent = newInstallations.components[componentIndex];

      if (
        name == "storeItemId" &&
        oldComponent.type == TemplateConfigItemType.LINKED &&
        oldComponent.storeItemId != newComponent.storeItemId
      ) {
        // Remove corresponding linked template
        setInstallation({
          ...newInstallations,
          components: replaceAtIndex(
            newInstallations.components,
            { ...newComponent, type: TemplateConfigItemType.DIRECT },
            componentIndex
          ),
          linkedTemplates: newInstallations.linkedTemplates.filter(
            (template) => template.templateId != oldComponent.linkedTemplateId
          )
        });
      }
    } else {
      setInstallation((prev) => ({
        ...prev,
        linkedTemplates: prev.linkedTemplates.map((linkedTemplate) =>
          replaceComponentAtIndex(linkedTemplate, componentIndex, data, name)
        )
      }));
    }
  };

  const handleInstallationAddOnChange = (
    data: AutocompleteData,
    name: keyof OnboardTemplateAddOnsArgs,
    addOnIndex: number,
    templateId: string
  ) => {
    if (templateId == installation.templateId) {
      setInstallation((prev) => replaceAddonAtIndex(prev, addOnIndex, data, name));
    } else {
      setInstallation((prev) => ({
        ...prev,
        linkedTemplates: prev.linkedTemplates.map((linkedTemplate) =>
          replaceAddonAtIndex(linkedTemplate, addOnIndex, data, name)
        )
      }));
    }
  };

  const handleInstallationActivityChange = (
    data: AutocompleteData,
    name: keyof OnboardTemplateActivitiesArgs,
    activityIndex: number,
    templateId: string
  ) => {
    if (templateId == installation.templateId) {
      setInstallation((prev) => replaceActivityAtIndex(prev, activityIndex, data, name));
    } else {
      setInstallation((prev) => ({
        ...prev,
        linkedTemplates: prev.linkedTemplates.map((linkedTemplate) =>
          replaceActivityAtIndex(linkedTemplate, activityIndex, data, name)
        )
      }));
    }
  };

  const handleOnboardProduct = () => {
    onboardProductMutation.mutate();
  };

  const purchaseTemplateQuery = useQuery({
    queryKey: ["purchased_template"],
    queryFn: async () => {
      const json: Response<{ isPurchased: boolean }> = await api
        .get(`product-template/${templateId}/purchase`)
        .json();
      const isSuccessfull = json.code === 200;
      return isSuccessfull ? json.data : undefined;
    }
  });

  const onboardProductMutation = useMutation({
    mutationFn: async () => {
      const requestData = {
        components: removeReplacedStoreItem(installation.components),
        activities: removeReplacedActivity(installation.activities),
        addOns: removeReplacedAddom(installation.addOns),
        linkedTemplates: installation.linkedTemplates.map((linkedTemplate) => ({
          templateId: linkedTemplate.templateId,
          components: removeReplacedStoreItem(linkedTemplate.components),
          activities: removeReplacedActivity(linkedTemplate.activities),
          addOns: removeReplacedAddom(linkedTemplate.addOns)
        }))
      };

      const json: Response<string> = await api
        .post(`product/template/${templateId}/onboard`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        showAlert(AlertType.SUCCESS, PRODUCT_OBOARDED_SUCCESSFULLY);
        navigate(`/products/${json.data}`);
      }
      return isSuccessfull;
    },
    onError: async (error: Error) => {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  });

  useEffect(() => {
    handleGetActivities();
  }, []);

  useEffect(() => {
    if (!template || !purchaseTemplateQuery.data?.isPurchased) {
      return;
    }
    const replacedLinkedTemplates: string[] = [];
    const components = formatComponentsToInstallationArgs(
      template.components,
      storeItems,
      replacedLinkedTemplates
    );
    setInstallation({
      templateId: template.id,
      components,
      activities: formatActivitiesToInstallationArgs(template.activities, businessActivities),
      addOns: formatAddonsToInstallationArgs(template.addOns, businessAddOns),
      linkedTemplates: template.linkedTemplates
        .map((linkedTemplate) => ({
          templateId: linkedTemplate.id,
          components: formatComponentsToLinkedInstallationArgs(
            linkedTemplate.components,
            storeItems
          ),
          activities: formatActivitiesToInstallationArgs(
            linkedTemplate.activities,
            businessActivities
          ),
          addOns: formatAddonsToInstallationArgs(linkedTemplate.addOns, businessAddOns)
        }))
        .filter((linkedComponent) => !replacedLinkedTemplates.includes(linkedComponent.templateId))
    });
  }, [template, storeItems, businessActivities, businessAddOns, purchaseTemplateQuery.data]);

  return {
    activeTemplate,
    setActiveTemplate,
    template,
    loaders: { ...templateLoaders, onboardingProduct: onboardProductMutation.isPending },
    completedReviews,
    setCompletedReviews,
    templateId,
    activeStep,
    setActiveStep,
    storeItems: storeItems?.items || [],
    businessActivities,
    businessAddOns,
    installation,
    handleInstallationComponentChange,
    handleInstallationAddOnChange,
    handleInstallationActivityChange,
    handleOnboardProduct
  };
};

export type UseTemplateInstallType = ReturnType<typeof useTemplateInstall>;
