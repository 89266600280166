import { EditStoreProps } from "types";
import React from "react";
import { Link } from "react-router-dom";
import ArrowLeft from "icons/ArrowLeft";
import Title from "components/Shared/Title";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Edit from "icons/Edit";
import Amount from "components/Shared/Amount";
import Table from "components/Shared/Table";
import { formatReadableDate } from "helpers/date";
import Dropdown from "components/Shared/Dropdown";
import Kebeb from "icons/Kebeb";
import Delete from "icons/Delete";
import Disclosure from "components/Shared/Disclosure";
import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import Dialog from "components/Shared/Dialog";
import InputGroup from "components/Shared/InputGroup";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import MeasurementUnit from "./MeasurementUnit";
import CurrencySymbol from "components/Shared/CurrencySymbol/CurrencySymbol";
import cx from "classnames";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";

const EditStoreItem = (props: EditStoreProps): React.ReactElement => {
  const {
    storeForm,
    storeItem,
    handleFormChange,
    handleFormSubmit,
    formIsValid,
    error,
    measurementUnits,
    loaders,
    handlePurchaseFormChange,
    handlePurchaseFormSubmit,
    handleCostFormSubmit,
    handleAvailableStockFormSubmit,
    purchaseForm,
    dialog,
    setDialog,
    handleConfirmDelete,
    deleteConfirmationMessage,
    handleDeleteConfirmation,
    handleDeleteCancellation
  } = props;
  const isProductStoreItem = storeItem?.type == "PRODUCT";
  return (
    <div className="mobile-full-screen">
      <div className="bg-white rounded-lg p-4 lg:p-10 w-full">
        <Link to="/store" className="font-bold text-sm inline-flex" data-testid="backLink">
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to store items
        </Link>
        <Title className="text-center mt-6 md:mt-0">Update store item</Title>
        <form
          onSubmit={handleFormSubmit}
          className="mt-10 md:mx-12 xl:mx-40"
          data-testid="storeItemForm">
          <Error error={error} />
          <div>
            <TextInput
              type="text"
              name="name"
              onChange={handleFormChange}
              value={storeForm.name}
              required
              label="Item name"
              data-testid="nameInput"
              disabled={isProductStoreItem}
            />
          </div>
          <div>
            <MeasurementUnit
              id="unit"
              name="unit"
              value={storeForm.unit}
              required
              onChange={handleFormChange}
              data-testid="measurementUnit"
              label="Unit of measurement"
              measurementUnit={storeForm.unit}
              measurementUnits={measurementUnits}
            />
          </div>
          <Button
            type="submit"
            data-testid="submitButton"
            disabled={!formIsValid}
            loading={loaders.savingStoreItem}
            className="w-full mt-6">
            Save
          </Button>
        </form>
      </div>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 pt-4">
        <div className="w-full bg-white rounded-lg p-4">
          <small
            className={cx(
              "text-primary flex space-x-1 font-bold justify-end cursor-pointer",
              isProductStoreItem && "invisible"
            )}
            onClick={(): void => setDialog("cost")}
            data-testid="updateCostPerUnit">
            <Edit /> <span>Update</span>
          </small>
          <div>
            <p className="text-grey-50">
              Cost per unit
              <InfoTooltip>This is derived from the most recent purchase amount.</InfoTooltip>
            </p>
            <div className="font-bold flex" data-testid="costPerUnit">
              <h2 className="text-xl md:text-3xl font-bold">
                <Amount amount={storeItem?.costPerUnit} />
              </h2>
              <small className="self-end">&nbsp;per {storeItem?.unit.symbol}</small>
            </div>
          </div>
        </div>

        <div className="w-full bg-white rounded-lg p-4">
          <small
            className="text-primary flex space-x-1 font-bold justify-end cursor-pointer"
            onClick={(): void => setDialog("stock")}
            data-testid="updateAvailableStock">
            <Edit /> <span>Update</span>
          </small>
          <div>
            <p className="text-grey-50">
              In stock
              <InfoTooltip>
                This is calculated by subtracting the purchases you add for this store item and the
                orders that have products containing this store item.
              </InfoTooltip>
            </p>
            <div className="font-bold" data-testid="availableStock">
              <h2 className="text-xl md:text-3xl font-bold">
                {storeItem?.availableStock}
                {storeItem?.unit.symbol}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white sm:rounded-lg p-4 lg:p-6 mt-4">
        <div className="flex flex-col sm:flex-row items-center sm:justify-between">
          <h2 className="text-xl md:text-3xl font-bold">
            {isProductStoreItem ? "Production" : "Purchase"} history
          </h2>
          <IconButton
            icon={<Plus />}
            data-testid="addPurchaseHistory"
            outlined
            onClick={(): void => setDialog("purchase")}>
            Add new {isProductStoreItem ? "batch" : "purchase"}
          </IconButton>
        </div>
        <div className="hidden sm:block">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadData>Date</Table.HeadData>
                <Table.HeadData>Measurement</Table.HeadData>
                <Table.HeadData>How many?</Table.HeadData>
                {!isProductStoreItem && <Table.HeadData>Total amount</Table.HeadData>}
                <Table.HeadData />
              </Table.Row>
            </Table.Head>
            <Table.Body className="text-dark-grey" data-testid="stockHistoryList">
              {storeItem?.stockHistory.map(
                (history): React.ReactElement => (
                  <Table.Row key={history.id} className="rounded-lg" data-testid="stockHistory">
                    <Table.Data data-testid="date">{formatReadableDate(history.date)}</Table.Data>
                    <Table.Data data-testid="measurement">{`${history.measurementValue}${storeItem.unit.symbol}`}</Table.Data>
                    <Table.Data data-testid="quantity">{history.quantity}</Table.Data>
                    {!isProductStoreItem && (
                      <Table.Data data-testid="price">
                        <Amount amount={history.price} />
                      </Table.Data>
                    )}
                    <Table.Data className="text-dark-grey">
                      <Dropdown
                        menu={[
                          {
                            text: "Delete",
                            icon: <Delete />,
                            onClick: () => handleConfirmDelete(history)
                          }
                        ]}>
                        <Kebeb />
                      </Dropdown>
                    </Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="block sm:hidden mt-4">
          <div className="flex">
            <span className="font-bold text-grey-50 w-2/5 px-4">Date</span>
            <span className="font-bold text-grey-50 w-2/5 px-4">Measurement</span>
            <span className="w-1/5"></span>
          </div>
          {storeItem?.stockHistory.map(
            (history): React.ReactElement => (
              <Disclosure key={history.id} data-testid="stockHistoryMobile">
                <div className="flex">
                  <span className="font-bold w-1/2" data-testid="date">
                    <b>{formatReadableDate(history.date)}</b>
                  </span>
                  <span className="font-bold w-1/2" data-testid="measurement">
                    {`${history.measurementValue}${storeItem.unit.symbol}`}
                  </span>
                </div>
                <div className="flex flex-col">
                  <small className="mt-2" data-testid="quantity">
                    <span className="font-bold w-24 inline-flex">How many?</span>
                    <Amount amount={history.quantity} />
                  </small>
                  {!isProductStoreItem && (
                    <small className="mb-2" data-testid="price">
                      <span className="font-bold w-24 inline-flex">Total amount:</span>
                      <Amount amount={history.price} />
                    </small>
                  )}
                  <span className="flex">
                    <button
                      data-testid="confirmDelete"
                      onClick={(): void => handleConfirmDelete(history)}
                      className="mobile-link text-red-500">
                      <Delete className="mobile-icon" />
                      <span>Delete</span>
                    </button>
                  </span>
                </div>
              </Disclosure>
            )
          )}
        </div>
      </div>
      <Dialog
        isOpen={dialog == "purchase"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "purchase" : "");
        }}
        title={`Add new ${isProductStoreItem ? "batch" : "purchase"}`}>
        <form onSubmit={handlePurchaseFormSubmit} className="" data-testid="purchaseForm">
          <Error error={error} />
          <div>
            <InputGroup>
              <div className="w-auto flex-1">
                <TextInput
                  type="number"
                  name="measurement"
                  onChange={handlePurchaseFormChange}
                  value={purchaseForm.measurement}
                  required
                  label="Item measurement"
                  data-testid="measurementInput"
                />
              </div>
              <div className="w-40 md:w-60">
                <MeasurementUnit
                  disabled
                  id="unit"
                  name="unit"
                  required
                  onChange={handlePurchaseFormChange}
                  data-testid="purchaseMeasurementUnit"
                  className="bg-soft-grey"
                  label="Unit"
                  measurementUnit={purchaseForm.unit || storeForm?.unit}
                  measurementUnits={measurementUnits}
                />
              </div>
            </InputGroup>
          </div>
          <div>
            <TextInput
              type="number"
              name="quantity"
              id="quantity"
              onChange={handlePurchaseFormChange}
              value={purchaseForm.quantity}
              required={true}
              label={`How many did you ${isProductStoreItem ? "produce" : "buy"}?`}
              data-testid="quantityInput"
              step={1}
            />
          </div>
          {!isProductStoreItem && (
            <div>
              <TextInput
                type="number"
                name="price"
                id="price"
                value={purchaseForm.price}
                required={true}
                onChange={handlePurchaseFormChange}
                label="Total amount"
                data-testid="amountInput"
                prepend={
                  <span className="text-xl">
                    <CurrencySymbol />
                  </span>
                }
              />
            </div>
          )}
          <Button
            type="submit"
            data-testid="purchaseSubmitButton"
            disabled={
              !purchaseForm.measurement ||
              !purchaseForm.quantity ||
              (!isProductStoreItem && !purchaseForm.price)
            }
            loading={loaders.savingPurchase}
            className="w-full mt-6">
            Save
          </Button>
        </form>
      </Dialog>

      <Dialog
        isOpen={dialog == "cost"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "cost" : "");
        }}
        title="Cost per unit">
        <form onSubmit={handleCostFormSubmit} className="" data-testid="costPerUnitForm">
          <Error error={error} />
          <div>
            <TextInput
              type="number"
              name="price"
              id="price"
              value={storeForm.price}
              required={true}
              onChange={handleFormChange}
              label="Total amount"
              data-testid="costAmountInput"
              prepend={
                <span className="text-xl">
                  <CurrencySymbol />
                </span>
              }
              append={<b>per {storeItem?.unit.symbol}</b>}
            />
          </div>
          <Button
            type="submit"
            data-testid="costSubmitButton"
            disabled={!storeForm.price}
            loading={loaders.savingCostPerUnit}
            className="w-full mt-6">
            Save
          </Button>
        </form>
      </Dialog>

      <Dialog
        isOpen={dialog == "stock"}
        setIsOpen={(open: boolean): void => {
          setDialog(open ? "stock" : "");
        }}
        title="In stock">
        <form
          onSubmit={handleAvailableStockFormSubmit}
          className=""
          data-testid="availableStockForm">
          <Error error={error} />
          <div>
            <InputGroup>
              <div className="w-auto flex-1">
                <TextInput
                  type="number"
                  name="measurement"
                  onChange={handleFormChange}
                  value={storeForm.measurement}
                  required
                  label="Item measurement"
                  data-testid="stockMeasurementInput"
                />
              </div>
              <div className="w-40 md:w-60">
                <MeasurementUnit
                  disabled
                  id="unit"
                  name="unit"
                  measurementUnit={storeItem?.unit.name}
                  measurementUnits={measurementUnits}
                  required
                  onChange={handleFormChange}
                  data-testid="stockMeasurementUnit"
                  className="bg-soft-grey"
                  label="Unit"
                />
              </div>
            </InputGroup>
          </div>
          <Button
            type="submit"
            data-testid="stockSubmitButton"
            disabled={!storeForm.measurement}
            loading={loaders.savingAvailableStock}
            className="w-full mt-6">
            Save
          </Button>
        </form>
      </Dialog>

      <ConfirmationDialog
        message={deleteConfirmationMessage}
        handleContinue={handleDeleteConfirmation}
        handleCancel={handleDeleteCancellation}
      />
    </div>
  );
};

export default EditStoreItem;
