import React from "react";
import { TemplateLibraryCategory } from "components/TemplateLibrary/TemplateLibraryCategory";
import { useTemplateLibrary } from "hooks/useTemplateLibrary/useTemplateLibrary";

const TemplateLibraryPage = (): React.ReactElement => {
  const templateLibraryProps = useTemplateLibrary();

  return <TemplateLibraryCategory {...templateLibraryProps} />;
};

export default TemplateLibraryPage;
