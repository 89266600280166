import { replaceAtIndex } from "helpers/array";
import { clean } from "helpers/string";
import {
  Activity,
  AutocompleteData,
  BusinessAddOn,
  Component,
  FormChangeEvent,
  LinkedTemplateArgs,
  LinkedTemplateComponentArgs,
  OnboardTemplateActivitiesArgs,
  OnboardTemplateAddOnsArgs,
  OnboardTemplateArgs,
  OnboardTemplateComponentArgs,
  ProductionActivity,
  StoreItemProduct,
  StoreItemRaw,
  StoreItems,
  TemplatAddOnCosts,
  TemplateConfigItemType
} from "types";

export const formatActivitiesToInstallationArgs = (
  activities: ProductionActivity[],
  businessActivities: Activity[]
) => {
  return activities.map((activity) => {
    const matchingActivity = businessActivities.find(
      (businessActivity) => businessActivity.name == activity.name
    );
    return {
      id: activity.id,
      replaced: !!matchingActivity,
      businessActivityId: matchingActivity?.id
    };
  });
};

export const formatAddonsToInstallationArgs = (
  addOns: TemplatAddOnCosts[],
  businessAddOns: BusinessAddOn[]
) => {
  return addOns.map((addOn) => {
    const matchingAddOn = businessAddOns.find((businessAddOn) => businessAddOn.name == addOn.name);
    return {
      id: addOn.id,
      replaced: !!matchingAddOn,
      businessAddOnId: matchingAddOn?.id
    };
  });
};

export const formatComponentsToInstallationArgs = (
  components: Component[],
  storeItems: StoreItems | undefined,
  replacedLinkedTemplates: string[]
): OnboardTemplateComponentArgs[] => {
  return components
    .map((component) => {
      const matchingStoreItem = storeItems?.items.find((item) =>
        componentIsMatching(item, component.name)
      );

      return {
        type:
          component.storeItemType == "RAW_MATERIAL"
            ? TemplateConfigItemType.DIRECT
            : TemplateConfigItemType.LINKED, // <-- indicates that this component links to another template in the linked templates array}
        id: component.id,
        replaced: !!matchingStoreItem,
        storeItemId: matchingStoreItem?.id, // <-- pass only if replaced is true
        linkedTemplateId: component.linkedTemplateId
      };
    })
    .map((component) => {
      if (component.type == TemplateConfigItemType.DIRECT) return component;
      const linkedTemplateIsReplaced = component.linkedTemplateId && component.replaced;
      if (linkedTemplateIsReplaced && component.linkedTemplateId) {
        replacedLinkedTemplates.push(component.linkedTemplateId);
      }
      return {
        ...component,
        linkedTemplateId: linkedTemplateIsReplaced ? undefined : component.linkedTemplateId,
        type: linkedTemplateIsReplaced
          ? TemplateConfigItemType.DIRECT
          : TemplateConfigItemType.LINKED
      };
    });
};

export const formatComponentsToLinkedInstallationArgs = (
  components: Component[],
  storeItems: StoreItems | undefined
): LinkedTemplateComponentArgs[] => {
  return components.map((component) => {
    const matchingStoreItem = storeItems?.items.find((item) =>
      componentIsMatching(item, component.name)
    );

    return {
      id: component.id,
      replaced: !!matchingStoreItem,
      storeItemId: matchingStoreItem?.id // <-- pass only if replaced is true
    };
  });
};

export const componentIsMatching = (item: StoreItemRaw | StoreItemProduct, name: string) => {
  return (
    (item.type == "RAW_MATERIAL" && formatForCompare(item.name, name)) ||
    (item.type == "PRODUCT" && formatForCompare(`${item.productName} - ${item.variantName}`, name))
  );
};

export const formatForCompare = (name1: string, name2: string): boolean => {
  return (
    clean(name1) === clean(name2) ||
    clean(name1) + "s" === clean(name2) ||
    clean(name2) + "s" === clean(name1)
  );
};

export const replaceComponentAtIndex = <T extends LinkedTemplateArgs>(
  template: T,
  componentIndex: number,
  data: AutocompleteData,
  name: keyof LinkedTemplateComponentArgs
): T => {
  const newComponent = {
    ...template.components[componentIndex],
    [name]: name == "replaced" ? data?.id == "link" : data?.id
  };

  const components = replaceAtIndex(template.components, newComponent, componentIndex);
  return {
    ...template,
    components
  };
};

export const replaceAddonAtIndex = <T extends LinkedTemplateArgs>(
  template: T,
  addOnIndex: number,
  data: AutocompleteData,
  name: keyof OnboardTemplateAddOnsArgs
): T => {
  const newAddOn = {
    ...template.addOns[addOnIndex],
    [name]: name == "replaced" ? data?.id == "link" : data?.id
  };

  const addOns = replaceAtIndex(template.addOns, newAddOn, addOnIndex);
  return {
    ...template,
    addOns
  };
};

export const replaceActivityAtIndex = <T extends LinkedTemplateArgs>(
  template: T,
  activityIndex: number,
  data: AutocompleteData,
  name: keyof OnboardTemplateActivitiesArgs
): T => {
  const newActivity = {
    ...template.activities[activityIndex],
    [name]: name == "replaced" ? data?.id == "link" : data?.id
  };

  const activities = replaceAtIndex(template.activities, newActivity, activityIndex);
  return {
    ...template,
    activities
  };
};

export const removeReplacedStoreItem = <T extends LinkedTemplateComponentArgs>(
  components: T[]
): T[] => {
  return components.map((component) => ({
    ...component,
    storeItemId: !component.replaced ? undefined : component.storeItemId
  }));
};

export const removeReplacedActivity = (
  activities: OnboardTemplateActivitiesArgs[]
): OnboardTemplateActivitiesArgs[] => {
  return activities.map((activity) => ({
    ...activity,
    businessActivityId: !activity.replaced ? undefined : activity.businessActivityId
  }));
};

export const removeReplacedAddom = (
  addOns: OnboardTemplateAddOnsArgs[]
): OnboardTemplateAddOnsArgs[] => {
  return addOns.map((addOn) => ({
    ...addOn,
    businessAddOnId: !addOn.replaced ? undefined : addOn.businessAddOnId
  }));
};
