import { ButtonProps, Size } from "types";
import React from "react";
import cx from "classnames";

const Button = (props: ButtonProps): React.ReactElement => {
  const {
    children,
    loading,
    size,
    className,
    disabled,
    gradient = true,
    icon,
    error,
    ...buttonProps
  } = props;
  const buttonSize: Size = size || "lg";
  const sizes: Record<Size, string> = {
    sm: "px-3 py-2 text-xs md:text-sm",
    md: "px-5 py-2.5 text-sm md:text-base",
    lg: "px-6 py-4"
  };
  const loaderSizes: Record<Size, string> = {
    sm: "h-4 w-4 ml-2",
    md: "h-5 w-5 ml-2",
    lg: "h-6 w-6 ml-3"
  };
  return (
    <button
      data-testid="button"
      type="button"
      {...buttonProps}
      disabled={disabled || loading}
      className={cx(
        "rounded-full text-center text-white disabled:opacity-50 disabled:cursor-not-allowed hover:disabled:to-secondary hover:to-primary inline-flex justify-center items-center font-bold",
        sizes[buttonSize],
        gradient && "bg-gradient-to-r from-primary to-secondary",
        className
      )}>
      {icon && (
        <span data-testid="icon" className={`text-${error ? "danger" : "white"} mr-2`}>
          {icon}
        </span>
      )}
      {children}
      {loading && (
        <svg
          data-testid="loadingIcon"
          className={cx("animate-spin text-white", loaderSizes[buttonSize])}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      )}
    </button>
  );
};

export default Button;
