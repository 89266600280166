import { useQuery } from "@tanstack/react-query";
import api from "config/api";
import { FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Response, TemplateConfiguration } from "types";
import { AlertType } from "types/enum";

export const useTemplateConfiguration = () => {
  const { templateId } = useParams();
  const { showAlert } = useAlert();

  const handleGetTemplateConfiguration = async (): Promise<TemplateConfiguration | undefined> => {
    try {
      const json: Response<TemplateConfiguration> = await api
        .get(`product-template/${templateId}/config`)
        .json();
      if (json.code === 200) {
        return json.data;
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["template_configurations", templateId],
    enabled: !!templateId,
    queryFn: handleGetTemplateConfiguration
  });

  // UseEffects
  useEffect(() => {
    if (templateId) {
      handleGetTemplateConfiguration();
    }
  }, [templateId]);

  return {
    configuration: data,
    loaders: {
      fetchingConfiguration: isLoading
    },
    templateId
  };
};

export type UseTemplateConfigurationType = ReturnType<typeof useTemplateConfiguration>;
