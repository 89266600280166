import Initials from "components/Shared/Initials/Initials";
import Title from "components/Shared/Title";
import React, { useEffect } from "react";
import { NotificationsProps, NotificationAction } from "types";
import cx from "classnames";
import { formatReadableTime, getDate } from "helpers/date";
import {
  actionMap,
  actionTypeMap,
  getNotificationDescription,
  getRelativeDate
} from "./Notifications.utils";
import IconButton from "components/Shared/IconButton";
import Select from "components/Shared/Select";
import { useComponentVisible } from "hooks/shared/useComponentVisible/useComponentVisible";
import TextInput from "components/Shared/TextInput";
import Check from "icons/Check";
import { endOfDay, endOfYesterday, startOfDay, startOfYesterday } from "date-fns";
import { DateRange } from "react-date-range";

const Notifications = (props: NotificationsProps): React.ReactElement => {
  const {
    notificationsSummary,
    notifications,
    handleLoadMore,
    loaders,
    actions,
    setActions,
    setActionBy,
    actionBy,
    members,
    actionAt,
    setActionAt,
    filtered,
    handleClearFilter
  } = props;
  const { ref, isComponentVisible } = useComponentVisible<HTMLDivElement>(false);
  const [displayedDate, setDisplayedDate] = React.useState("Date");
  const [selectedDiffIndex, setSelectedDiffIndex] = React.useState<number | undefined>(-1);

  useEffect(() => {
    if (actionAt === undefined) {
      setDisplayedDate("Date");
    }
  }, [actionAt]);

  const dateFilters = [
    { label: "Today", dayDiff: 0 },
    { label: "Yesterday", dayDiff: -1 },
    { label: "Custom", dayDiff: undefined }
  ];

  const changeCustomDateFilter = (index: number): void => {
    const { dayDiff, label } = dateFilters[index];

    if (dayDiff === 0) {
      setActionAt({
        from: getDate(startOfDay(new Date())),
        to: getDate(endOfDay(new Date())) + " 23:59:59"
      });
    }
    if (dayDiff === -1) {
      setActionAt({
        from: getDate(startOfYesterday()),
        to: getDate(endOfYesterday()) + " 23:59:59"
      });
    }
    setDisplayedDate(label);
    setSelectedDiffIndex(index);
  };

  return (
    <div className="">
      <Title className="pt-2 pb-6 px-4 sm:px-0">Notifications</Title>
      <div className="flex flex-col px-4 md:px-0 md:flex-row justify-between items-center">
        {filtered && (
          <div className="w-full md:w-auto mb-4 md:mb-0">
            <span className="text-grey-50 mb-2 mr-2">{notificationsSummary?.total} results</span>
            <span className="link cursor-pointer text-sm underline" onClick={handleClearFilter}>
              Clear filters
            </span>
          </div>
        )}
        <div className="flex flex-grow flex-wrap justify-center md:justify-end items-center gap-3">
          <span className="mb-2">Filter by</span>
          <div className="w-32">
            <Select
              simple
              inputSize="sm"
              name="reuse"
              value={actions?.[0] || ""}
              onChange={(e): void => setActions([e.target.value] as NotificationAction[])}
              label=""
              defaultValue="">
              <option value="">All Actions</option>
              <option value="EXPENSE">Expense</option>
              <option value="ORDER">Order</option>
              <option value="ORDER_PAYMENT">Order Payment</option>
              <option value="STORE_ITEM_STOCK">Store item stock</option>
              <option value="MEMBER">Member</option>
            </Select>
          </div>
          <div className="w-32">
            <Select
              simple
              inputSize="sm"
              name=""
              value={actionBy || ""}
              onChange={(e): void => setActionBy(e.target.value)}
              label=""
              defaultValue="">
              <option value="">All members</option>
              {members.map((member) => (
                <option key={member.userId} value={member.userId}>
                  {member.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-32 relative" ref={ref}>
            <span>
              <TextInput
                simple
                inputSize="sm"
                type="text"
                value={displayedDate}
                name=""
                placeholder=""
                className="!pt-0 dateInput text-center"
                readOnly
                label=""
              />
            </span>
            <ul
              data-testid="dateFilterList"
              className={cx(
                "shadow-lg text-sm hidden absolute -mt-1 left-0 right-0 top-full z-10 bg-soft-grey rounded-lg hover:!block",
                isComponentVisible && "!block"
              )}>
              {dateFilters.map((filter, index) => (
                <li
                  data-testid="dateFilterItem"
                  className={cx(
                    "py-1 px-4 hover:bg-primary hover:text-white cursor-pointer first:rounded-t-lg last:rounded-b-lg flex justify-between",
                    selectedDiffIndex === index && "bg-[rgba(0,0,0,0.1)]"
                  )}
                  key={index}
                  onClick={(): void => {
                    changeCustomDateFilter(index);
                  }}>
                  {filter.label}
                  {selectedDiffIndex === index && <Check width="20" height="20" />}
                </li>
              ))}
              {dateFilters.findIndex(
                (filter, index) => selectedDiffIndex === index && filter.dayDiff === undefined
              ) >= 0 && (
                <li>
                  <span className="rounded-lg w-full" data-testid="customDatePicker">
                    <div className="bg-[rgba(0,0,0,0.1)] p-2 flex flex-col w-full justify-center">
                      <DateRange
                        rangeColors={["#00A89D"]}
                        className="absolute right-0 top-[85%]"
                        onChange={(item: { range1?: { startDate: string; endDate: string } }) => {
                          setActionAt({
                            from: getDate(item.range1?.startDate || new Date()),
                            to: getDate(item.range1?.endDate || new Date())
                          });
                        }}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={[
                          {
                            startDate: actionAt ? new Date(actionAt.from) : new Date(),
                            endDate: actionAt ? new Date(actionAt.to) : new Date()
                          }
                        ]}
                        direction="vertical"
                      />
                    </div>
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-white sm:rounded-lg p-4 lg:p-6 mt-2 md:mt-7 text-sm">
        {notifications.map((notification) => (
          <div className="text-grey-50 flex py-4 border-b border-grey-30" key={notification.id}>
            <span className="mr-4">
              <Initials text={notification.actionByName} />
            </span>
            <div>
              <p className={cx("mb-0.5", !notification.seen && "font-bold text-dark-grey")}>
                {`${notification.actionByName} ${actionTypeMap[notification.actionType]} ${
                  actionMap[notification.action]
                }: `}
                {getNotificationDescription(notification.action, notification.data)}
              </p>
              <p>{`${getRelativeDate(notification.actionTime)} • ${formatReadableTime(
                notification.actionTime
              ).toLowerCase()}`}</p>
            </div>
          </div>
        ))}
        {notifications.length === 0 && !loaders.fetchingNotifications && (
          <p>No notifications found</p>
        )}
        <div className="py-4">
          {((notifications.length === 0 && loaders.fetchingNotifications) ||
            (notifications.length > 0 &&
              notifications.length < (notificationsSummary?.total || 0))) && (
            <IconButton onClick={handleLoadMore} outlined loading={loaders.fetchingNotifications}>
              {loaders.fetchingNotifications ? "Loading..." : "Load more"}
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
