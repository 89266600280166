import {
  FormChangeEvent,
  ProductDetailVariant,
  ProductOfferingForm,
  ProductOfferingFormOptionItem,
  ProductWithVariants
} from "types";

export const getOfferingRequestData = (offering: ProductOfferingForm) => {
  return {
    ...offering,
    options: offering.options.map((option) => ({
      ...option,
      items: option.items.map((item) => ({
        variantId: item.variantId,
        quantity: +item.quantity,
        unitPrice: +item.unitPrice
      }))
    }))
  };
};

export const resetItem = (
  event: FormChangeEvent,
  product: ProductWithVariants | undefined,
  variant: ProductDetailVariant | undefined
): Partial<ProductOfferingFormOptionItem> => {
  const { name } = event.target;
  const updatedItem: Partial<ProductOfferingFormOptionItem> = {};

  if (name === "productId") {
    updatedItem.variantId = "";
    updatedItem.unitPrice = "";
    updatedItem.name = "";
  }

  if (name === "variantId") {
    updatedItem.unitPrice = variant?.defaultSellingPrice?.toString() || "";
    updatedItem.name = `${product?.name}-${variant?.name}`;
  }

  return updatedItem;
};
