export const ERROR_OCCURRED =
  "An error occurred, please retry or contact the system administrator for further assistance.";
export const TIMEOUT_ERROR = "We could not complete your request, please try again.";
export const INCORRECT_CREDENTIALS = "The credentials provided are incorrect, please try again.";
export const ACCOUNT_EXISTS =
  "An account exists with this email, please login or try again with a different email";
export const BUSINESS_INFO_ERROR =
  "An error occurred while fetching your business information, please try again";
export const ONBOARDING_MEMBER_ERROR =
  "An error occured while profiling your account, please try again";
export const ONBOARDING_ERROR = "An error occured while profiling your business, please try again";
export const REQUEST_SUCCESSFUL = "Request completed successfully";
export const COMPONENT_ATTACHED = "Component attached successfully";
export const COMPONENT_DETACHED = "Component detached successfully";
export const FETCH_FAILED = "Records could not be fetched. please try again";
export const ADDON_ADDED = "AddOn added successfully";
export const ADDON_REMOVED = "AddOn removed successfully";
export const PRODUCTION_ACTIVITY_ADDED = "Activity added successfully";
export const PRODUCTION_ACTIVITY_REMOVED = "Activity removed successfully";
export const EXPENSE_ADDED = "Expense created successfully, allocate activities to continue";
export const INVITE_INVALID =
  "We were unable to find any details for your invite, please contact your inviter";
export const INVITE_ACCEPTED = "Invite accepted successfully";
export const NOTIFICATION_ERROR = "Notifications could not be fetched";
export const INVOICE_GENERATION_ERROR =
  "An error occurred while generating your invoice, please try again";
export const TEMPLATE_COMPONENT_ATTACHED = "Teplate component attached successfully";
export const TEMPLATE_COMPONENT_DETACHED = "Teplate component detached successfully";
export const PRODUCT_OBOARDED_SUCCESSFULLY = "Product successfully created from template";
