import React, { SVGProps } from "react";

const Sort = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      width="20"
      height="30"
      viewBox="0 0 24 36"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_61_161" maskUnits="userSpaceOnUse" x="0" y="12" width="24" height="24">
        <rect y="12" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_61_161)">
        <path
          d="M12 27.3753L6 21.3753L7.4 19.9753L12 24.5753L16.6 19.9753L18 21.3753L12 27.3753Z"
          fill="currentColor"
          className="arrowDown"
        />
      </g>
      <mask id="mask1_61_161" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" transform="matrix(1 0 0 -1 0 24)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask1_61_161)">
        <path
          d="M12 8.62466L6 14.6247L7.4 16.0247L12 11.4247L16.6 16.0247L18 14.6247L12 8.62466Z"
          fill="currentColor"
          className="arrowUp"
        />
      </g>
    </svg>
  );
};

export default Sort;
