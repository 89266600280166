import Title from "components/Shared/Title";
import React from "react";

export const Collections = (): React.ReactElement => {
  return (
    <div className="bg-white sm:rounded-lg p-4 lg:p-6 mt-2 md:mt-7">
      <div className="mt-7 pb-10" data-testid="products">
        <div
          className="flex flex-col items-center justify-center bg-white rounded-lg p-4 lg:p-6"
          data-testid="noItems">
          <img src="/images/svg/collection.svg" />
          <Title className="pt-10 pb-3 text-center">Collections are coming soon</Title>
          <p className="text-grey-50 px-4 text-center">
            Soon you can create a collection of product offerings as an online shopfront for your
            customers
          </p>
        </div>
      </div>
    </div>
  );
};
