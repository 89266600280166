import IconButton from "components/Shared/IconButton";
import Title from "components/Shared/Title";
import Plus from "icons/Plus";
import React from "react";
import { Link } from "react-router-dom";

export type NoOfferingsProps = {
  message: string;
};

export const NoOfferings = ({ message }: NoOfferingsProps) => {
  return (
    <div
      className="flex flex-col items-center justify-center bg-white rounded-lg p-4 lg:p-6"
      data-testid="noItems">
      <img src="/images/svg/street.svg" />
      <Title className="pt-10 pb-3 text-center">No product offerings</Title>
      <p className="text-grey-50 px-4 text-center">{message}</p>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto items-center mt-4">
        <Link
          to={"/sales/shop-front/product-offerings/create"}
          data-testid=""
          className="w-full md:w-auto">
          <IconButton
            icon={<Plus />}
            type="button"
            className="w-full md:w-60 !bg-black"
            gradient={false}
            data-testid="closedButton">
            Create product offering
          </IconButton>
        </Link>
      </div>
    </div>
  );
};
